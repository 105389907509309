import React from "react";

function ReadMoreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57"
      height="57"
      fill="none"
      viewBox="0 0 57 57"
    >
      <path
        fill="#000"
        d="M31.886 55.596l23.76-23.73c.432-.452.771-.984.998-1.567a4.74 4.74 0 000-3.607 4.745 4.745 0 00-.998-1.566l-23.76-23.73A4.77 4.77 0 0028.512 0a4.775 4.775 0 00-3.374 1.396 4.763 4.763 0 000 6.74L40.819 23.75H4.752c-1.26 0-2.469.5-3.36 1.39a4.743 4.743 0 000 6.712c.891.89 2.1 1.39 3.36 1.39H40.82L25.138 48.857a4.746 4.746 0 00-1.04 5.195 4.746 4.746 0 001.04 1.544 4.753 4.753 0 006.748 0z"
      ></path>
    </svg>
  );
}

export default ReadMoreIcon;
