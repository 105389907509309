import React from "react";

function LinkedinBlogIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_1_142)">
        <path
          fill="#000243"
          d="M.902 3.017c0-.62.207-1.13.621-1.533C1.938 1.08 2.477.88 3.14.88c.65 0 1.178.198 1.58.595.415.408.622.941.622 1.598 0 .595-.201 1.09-.604 1.487-.414.409-.959.613-1.634.613h-.018c-.65 0-1.178-.204-1.58-.613-.403-.41-.604-.923-.604-1.543zm.23 16.263V6.864h3.944V19.28H1.133zm6.128 0h3.943v-6.933c0-.433.047-.768.142-1.003.166-.422.417-.778.755-1.07.337-.29.76-.436 1.27-.436 1.326 0 1.989.936 1.989 2.807v6.635h3.943V12.16c0-1.833-.415-3.224-1.243-4.172-.83-.948-1.924-1.422-3.286-1.422-1.527 0-2.717.688-3.57 2.063v.037h-.018l.018-.037V6.864H7.26c.024.397.036 1.63.036 3.699 0 2.07-.012 4.975-.036 8.717z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1_142">
          <path
            fill="#fff"
            d="M0 0H18.4V18.4H0z"
            transform="translate(.902 .88)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LinkedinBlogIcon;
