import { useState, useEffect } from 'react';
import axios from 'axios';

// Utility function for converting prices
export const calculateConvertedPrice = (priceInEur, currency, exchangeRates) => {
  switch (currency) {
    case 'USD':
      return priceInEur / exchangeRates.eur_to_usd;
    case 'GBP':
      return priceInEur / exchangeRates.eur_to_gbp;
    default:
      return priceInEur; // Default is EUR
  }
};

// Utility function to fetch exchange rates from an API
export const fetchExchangeRates = async () => {
  try {
    const response = await axios.get('https://7sq9r5uhnl.execute-api.eu-north-1.amazonaws.com/public/api/currency');
    const rates = response.data.body;

    // Store rates in localStorage
    localStorage.setItem('exchangeRates', rates);
    return rates;
  } catch (error) {
    console.error('Error fetching exchange rates:', error);
    return null;
  }
};

// Custom hook to manage currency and exchange rates
export const useCurrency = () => {
  const [currency, setCurrency] = useState('EUR');
  const [exchangeRates, setExchangeRates] = useState({
    eur_to_usd: 1,
    eur_to_gbp: 1,
  });

  useEffect(() => {
    const savedCurrency = localStorage.getItem('currency') || 'EUR';
    setCurrency(savedCurrency);

    const savedRates = JSON.parse(localStorage.getItem('exchangeRates'));

    if (savedRates) {
      setExchangeRates(savedRates);
    } else {
      // If rates are not in localStorage, fetch them
      const fetchRates = async () => {
        const rates = await fetchExchangeRates();
        if (rates) {
          setExchangeRates(rates);
        }
      };
      fetchRates();
    }
  }, []);

  const handleCurrencyChange = (newCurrency) => {
    setCurrency(newCurrency);
    localStorage.setItem('currency', newCurrency);
  };

  const convertPrice = (price) => {
    return calculateConvertedPrice(price, currency, exchangeRates)
  }

  const currencyCharacter = currency == "USD" ? "$" : currency == "GBP" ? "£" : "€"

  return { currency, currencyCharacter, exchangeRates, handleCurrencyChange, convertPrice };
};
