import React from "react";

function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill="#0E3AFF"
        d="M15.573 12.427V2.972a1.572 1.572 0 10-3.145 0v9.455H2.973a1.572 1.572 0 100 3.145h9.455v9.455a1.572 1.572 0 003.145 0v-9.455h9.455a1.572 1.572 0 000-3.145h-9.455z"
      ></path>
    </svg>
  );
}

export default PlusIcon;
