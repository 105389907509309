import React from "react";

function HiddenCostIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 512 512"
    >
      <path d="M256 59.55c108.5 0 196.45 87.96 196.45 196.45S364.5 452.45 256 452.45c-108.49 0-196.45-87.96-196.45-196.45S147.51 59.55 256 59.55zM256 0c70.68 0 134.69 28.66 181.02 74.98C483.34 121.31 512 185.31 512 256c0 70.69-28.66 134.7-74.98 181.02C390.7 483.34 326.69 512 256 512c-70.69 0-134.69-28.66-181.02-74.98C28.66 390.69 0 326.68 0 256c0-70.69 28.66-134.69 74.98-181.02C121.31 28.66 185.31 0 256 0zm163.28 92.72C377.49 50.94 319.76 25.09 256 25.09c-63.77 0-121.5 25.85-163.28 67.63C50.94 134.5 25.09 192.23 25.09 256c0 63.76 25.85 121.49 67.63 163.28 41.78 41.78 99.51 67.63 163.28 67.63 63.77 0 121.5-25.85 163.28-67.63 41.78-41.78 67.63-99.51 67.63-163.28 0-63.77-25.85-121.5-67.63-163.28zM285.59 257.43l-5.38 22.41h-37.35c4.83 15.28 15.36 22.92 31.48 22.92 17.71 0 34.29-5.71 49.69-17.12v45.83c-12.8 8.73-28.25 13.09-46.33 13.09-29.26 0-50.92-6.67-64.93-20.02-13.98-13.34-22.54-28.25-25.69-44.7h-22.24l5.83-22.41h13.6c-.17-1.22-.25-3.4-.25-6.51 0-3.27.08-5.62.25-7.09h-16.41l5.83-22.41h13.39c10.66-42.14 39.96-63.2 87.84-63.2 15.66 0 31.52 4.19 47.6 12.58l-11.08 44.58c-11.17-10.25-22.83-15.36-34.96-15.36-15.91 0-26.7 7.13-32.28 21.4h44.41l-5.38 22.41h-43.31c-.16 1.64-.25 3.99-.25 7.09v6.51h45.92z"></path>
    </svg>
  );
}

export default HiddenCostIcon;
