import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';

import SearchIcon from "../../icons/search";
import CloseIcon from '../../icons/close';
import { Link } from 'react-router-dom';

const SearchBox = ({ searchText, specialLeft }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialFocus, setInitialFocus] = useState();

  const fetchSuggestions = useCallback(
    debounce(async (queryValue) => {
      try {
        setIsLoading(true);
        setDropdownVisible(true); // Show the dropdown immediately
        const response = await axios.post('https://7sq9r5uhnl.execute-api.eu-north-1.amazonaws.com/public/api/query', {
          query: queryValue,
        });

        const data = JSON.parse(response.data.body);
        setSuggestions(data);
        setIsLoading(false);
        setDropdownVisible(true); // Always show the dropdown, even if data is empty
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setIsLoading(false);
      }
    }, 300), // Adjust the delay as needed
    []
  );

  useEffect(() => {

    if (!initialFocus) {
      return;
    }

    if (query) {
      fetchSuggestions(query);
    } else {
      setSuggestions([]); // Clear suggestions when query is empty
      setDropdownVisible(true); // Show dropdown but no results
    }

    // Clean up the debounced function when the component unmounts or query changes
    return () => {
      fetchSuggestions.cancel();
    };
  }, [query, fetchSuggestions]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleClickOutside = (e) => {
    if (!e.target.closest('.search_input_wrapper') && !e.target.closest('.search_dropdown')) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const dateFormatMonthDay = (date) => {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return months[date.split("-")[1] - 1];
  }

  const handleInputFocus = () => {
    setDropdownVisible(true);
    if (!initialFocus) {
      setInitialFocus(true);
    }
  }

  return (
    <>
      <div id="search_box" className={specialLeft ? "search_box_left" : ""}>
        <div className={`wrapper ${isDropdownVisible ? 'search_box_mobile_focus' : ''}`}>
          <h1>{searchText || <>Effortless bookings<br />for football tickets</>}</h1>
          {specialLeft && <span className="search_box_subtitle">Compare multiple platforms with one simple search.</span>}
          <div className="search_input_wrapper">
            <SearchIcon />
            <input
              type="text"
              placeholder="Event, city or team"
              value={query}
              onChange={handleInputChange}
              onFocus={() => {handleInputFocus()}}
            />
            <div className="search_back_mobile_icon" onClick={() => setDropdownVisible(false)}>
              <CloseIcon />
            </div>
          </div>
          {isLoading && suggestions.length == 0 ? (
            <></>
          ) : (
            <>
              {suggestions.length > 0 ? (
                <div className={`search_dropdown ${isDropdownVisible ? 'active' : ''}`}>
                  {suggestions.some(suggestion => suggestion.type === 'team') && (
                    <h2>Clubs</h2>
                  )}
                  {suggestions.filter(suggestion => suggestion.type === 'team').map((suggestion, index) => (
                    <Link to={"/" + suggestion.name.toLowerCase().replaceAll(" ", "-") + "-tickets/club/" + suggestion.id} className="search_dropdown_item" key={index} onClick={() => setDropdownVisible(false)}>
                      <div className="search_dropdown_item_img"
                        style={{ backgroundImage: "url('https://ticketseal-blog-bucket.s3.eu-north-1.amazonaws.com/clubs/club" + (suggestion.id) + ".png')" }}
                      ></div>
                      <span className="search_dropdown_item_text">{suggestion.name}</span>
                    </Link>
                  ))}

                  {suggestions.some(suggestion => suggestion.type === 'event') && (
                    <h2>Events</h2>
                  )}
                  {suggestions.filter(suggestion => suggestion.type === 'event').map((suggestion, index) => (
                    <Link to={"/" + suggestion.name.toLowerCase().replaceAll(" ", "-") + "-tickets/event/" + suggestion.id} className="search_dropdown_item" key={index} onClick={() => setDropdownVisible(false)}>
                      <div className="search_dropdown_item_img search_dropdown_item_date">
                        <span>{suggestion.event_date.slice(8, 10)}</span>
                        <span>{dateFormatMonthDay(suggestion.event_date)}</span>
                      </div>
                      <div className="search_dropdown_info">
                        <span className="search_dropdown_item_text">{suggestion.name}</span>
                        <div className="search_dropdown_details">{suggestion.event_time} - {suggestion.location}</div>
                      </div>
                    </Link>
                  ))}
                </div>
              ) : (
                <div className="no_results_message"></div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchBox;
