import React from "react";

function AlertIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="26"
      fill="none"
      viewBox="0 0 19 26"
    >
      <path
        stroke="#0E3AFF"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M15.951 16.045v-5.437a6.612 6.612 0 00-6.603-6.603h-.129a6.612 6.612 0 00-6.603 6.603v5.437a1.3 1.3 0 01-.163.63L.994 19.37a1.327 1.327 0 001.179 1.96h14.21c1.015 0 1.656-1.085 1.178-1.96l-1.458-2.695a1.334 1.334 0 01-.164-.63h.012z"
      ></path>
      <path
        stroke="#0E3AFF"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M5.638 21.33a3.676 3.676 0 003.651 3.337 3.676 3.676 0 003.652-3.337M11.903 4.507c.035-.164.058-.339.058-.502A2.679 2.679 0 009.29 1.333a2.679 2.679 0 00-2.672 2.672c0 .175.012.35.059.502"
      ></path>
    </svg>
  );
}

export default AlertIcon;
