import React from "react";

function ArticleDateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#0E3AFF"
        d="M14.375 14.056c.463 0 .84.382.84.85 0 .471-.377.852-.84.852H9.628a.846.846 0 01-.84-.851c0-.47.377-.85.84-.85h4.747zm-3.292-12.68l1.226.974c.503.393.838.911.953 1.456.132.6-.009 1.189-.406 1.698L5.552 14.95a1.75 1.75 0 01-1.358.679l-2.911.036a.333.333 0 01-.327-.26l-.661-2.868c-.115-.527 0-1.073.335-1.493l5.178-6.702a.26.26 0 01.353-.044L8.34 6.03a.703.703 0 00.538.152.785.785 0 00.679-.867.873.873 0 00-.274-.527L7.166 3.091a.315.315 0 01-.053-.437l.82-1.064c.76-.974 2.083-1.063 3.15-.214z"
      ></path>
    </svg>
  );
}

export default ArticleDateIcon;
