import React from 'react'
import { Helmet } from 'react-helmet'

function Sitemap() {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sitemap</title>
                <meta name="description" content="" />
            </Helmet>
            <div className="wrapper">
                <h1 className="sitemap_title">Sitemap</h1>
                <div className="sitemap_item">
                    <h2>Index</h2>
                    <h4>https://ticketseal.com/</h4>
                </div>
                <div className="sitemap_item">
                    <h2>Contact</h2>
                    <h4>https://ticketseal.com/contact</h4>
                </div>
                <div className="sitemap_item">
                    <h2>Events</h2>
                    <h4>https://ticketseal.com/{"{"}eventTitle{"}"}/event/{"{"}eventId{"}"}/</h4>
                </div>
                <div className="sitemap_item">
                    <h2>Clubs</h2>
                    <h4>https://ticketseal.com/{"{"}clubTitle{"}"}/club/{"{"}clubId{"}"}/</h4>
                </div>
                <div className="sitemap_item">
                    <h2>Leagues</h2>
                    <h4>https://ticketseal.com/{"{"}leagueTitle{"}"}/league/{"{"}leagueId{"}"}/</h4>
                </div>
                <div className="sitemap_item">
                    <h2>Blog</h2>
                    <h4>https://ticketseal.com/blog</h4>
                </div>
                <div className="sitemap_item">
                    <h2>Article</h2>
                    <h4>https://ticketseal.com/blog/{"{"}articleTitle{"}"}/blog/{"{"}articleId{"}"}</h4>
                </div>
            </div>
        </>
    )
}

export default Sitemap