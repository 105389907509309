import React from "react";

function InstagramBlogIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_1_166)">
        <path
          fill="#000243"
          d="M7.036 10.08a3.067 3.067 0 116.133 0 3.067 3.067 0 01-6.133 0zm-1.658 0a4.724 4.724 0 109.448 0 4.724 4.724 0 00-9.448 0zm8.53-4.911a1.104 1.104 0 102.21 0 1.104 1.104 0 00-2.21 0zm-7.523 12.4c-.897-.041-1.385-.19-1.709-.317a2.861 2.861 0 01-1.058-.688 2.843 2.843 0 01-.688-1.058c-.126-.324-.276-.811-.317-1.708-.044-.97-.053-1.261-.053-3.718s.01-2.747.053-3.718c.041-.897.191-1.384.317-1.708.167-.43.366-.736.688-1.059a2.84 2.84 0 011.058-.688c.324-.126.812-.275 1.709-.316.97-.045 1.26-.054 3.717-.054s2.747.01 3.718.054c.897.04 1.383.19 1.708.316.43.167.736.367 1.058.688.322.322.521.63.689 1.059.126.324.275.811.316 1.708.045.97.054 1.261.054 3.718 0 2.456-.01 2.747-.054 3.718-.04.897-.19 1.384-.316 1.708-.168.43-.367.736-.689 1.058a2.854 2.854 0 01-1.058.688c-.324.126-.811.276-1.708.317-.97.044-1.261.053-3.718.053-2.456 0-2.748-.009-3.717-.053zM6.309.936c-.98.044-1.648.2-2.233.427a4.514 4.514 0 00-1.63 1.061 4.502 4.502 0 00-1.061 1.63c-.228.585-.383 1.254-.427 2.233-.046.981-.056 1.295-.056 3.793s.01 2.812.056 3.793c.044.98.2 1.648.427 2.233.235.605.55 1.118 1.061 1.63a4.513 4.513 0 001.63 1.061c.585.227 1.253.383 2.233.427.982.045 1.294.056 3.793.056s2.812-.01 3.793-.056c.98-.044 1.648-.2 2.233-.427a4.526 4.526 0 001.63-1.061 4.518 4.518 0 001.06-1.63c.228-.585.384-1.254.428-2.233.045-.982.055-1.295.055-3.793s-.01-2.812-.055-3.793c-.044-.98-.2-1.648-.427-2.233a4.524 4.524 0 00-1.061-1.63 4.512 4.512 0 00-1.63-1.061c-.585-.227-1.254-.383-2.232-.427C12.915.89 12.6.88 10.103.88c-2.5 0-2.812.01-3.794.056"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1_166">
          <path
            fill="#fff"
            d="M0 0H18.4V18.4H0z"
            transform="translate(.902 .88)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default InstagramBlogIcon;
