import React from "react";

function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M145.869-185.869v-79.218h74.913v-289.869q0-88.914 51.478-160.588 51.478-71.674 138.131-90.369v-18.609q0-29.239 20.302-49.707 20.303-20.467 49.307-20.467t49.307 20.467q20.302 20.468 20.302 49.707v18.609q86.653 18.13 138.414 90 51.761 71.87 51.761 160.957v289.869h74.912v79.218H145.869zM480.283-60.782q-34.95 0-59.878-24.994-24.927-24.994-24.927-60.093h169.609q0 35.261-25.044 60.174Q515-60.782 480.283-60.782z"></path>
    </svg>
  );
}

export default CheckIcon;
