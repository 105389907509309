import React from "react";

function BreadcrumbsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#5E5F74"
        d="M13.093 12.5l-5.091 5.056L9.456 19l6.546-6.5L9.456 6 8.002 7.444l5.091 5.056z"
      ></path>
    </svg>
  );
}

export default BreadcrumbsIcon;
