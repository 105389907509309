import React from "react";

function FacebookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.001"
      height="24.001"
      viewBox="1026.083 1559 24.001 24.001"
    >
      <g fillRule="evenodd" data-name="Group 36">
        <path
          fill="#0e3aff"
          d="M1050.085 1571c0 5.99-4.389 10.956-10.126 11.856v-8.386h2.797l.532-3.47h-3.329v-2.25c0-.95.465-1.875 1.956-1.875h1.513v-2.953s-1.373-.234-2.686-.234c-2.741 0-4.533 1.661-4.533 4.669V1571h-3.047v3.469h3.047v8.386c-5.737-.9-10.126-5.865-10.126-11.855 0-6.628 5.374-12.001 12.001-12.001 6.628 0 12.001 5.373 12.001 12z"
          data-name="Path 22"
        ></path>
        <path
          fill="#fff"
          d="M1042.756 1574.47l.532-3.47h-3.329v-2.25c0-.95.465-1.875 1.956-1.875h1.513v-2.953s-1.373-.234-2.686-.234c-2.741 0-4.533 1.661-4.533 4.669V1571h-3.047v3.469h3.047v8.386a12.091 12.091 0 003.75 0v-8.386h2.797z"
          data-name="Path 23"
        ></path>
      </g>
    </svg>
  );
}

export default FacebookIcon;
