import React from "react";

function ContactIcon() {
  return (
    <svg className="contact_icon__90N4t" viewBox="0 0 504.124 504.124">
      <path
        fill="#d07c40"
        d="M0 200.862L252.062 51.2l252.062 149.662v220.554H0V200.862z"
      ></path>
      <path
        fill="#efefef"
        d="M55.138 11.815h393.846c8.665 0 15.754 7.089 15.754 15.754v275.692c0 8.665-7.089 15.754-15.754 15.754H55.138c-8.665 0-15.754-7.089-15.754-15.754V27.569c.001-8.664 7.09-15.754 15.754-15.754z"
      ></path>
      <path
        fill="#26a6d1"
        d="M415.902 43.323l25.206-31.508h7.877c8.665 0 15.754 7.089 15.754 15.754v15.754h-48.837zm-204.8 0l25.206-31.508h55.138L266.24 43.323h-55.138zm-171.717 0V27.569c0-8.665 7.089-15.754 15.754-15.754h39.385L69.317 43.323H39.385z"
      ></path>
      <path
        fill="#efc75e"
        d="M504.123 468.677c0 12.997-10.634 23.631-23.631 23.631H23.631C10.634 492.309 0 481.674 0 468.677V200.862l252.062 110.277 252.062-110.277v267.815h-.001z"
      ></path>
      <path
        fill="#e8c15b"
        d="M504.123 468.677c0 12.997-10.634 23.631-23.631 23.631H23.631C10.634 492.309 0 481.674 0 468.677v-47.262l504.123-220.554v267.816z"
      ></path>
      <path
        fill="#d6d9db"
        d="M160.295 151.631l3.151 16.542-25.206-39.385-35.446 55.138c-.394 5.12 22.449 9.058 50.806 9.058s51.2-3.938 51.2-9.058l-31.114-46.08-13.391 13.785zm3.151-21.662c5.514 0 9.846-4.332 9.846-9.846s-4.332-9.846-9.846-9.846-9.846 4.332-9.846 9.846 4.332 9.846 9.846 9.846zm80.739-3.938h157.538c4.332 0 7.877-3.545 7.877-7.877s-3.545-7.877-7.877-7.877H244.185c-4.332 0-7.877 3.545-7.877 7.877s3.544 7.877 7.877 7.877zm157.538 15.754H244.185c-4.332 0-7.877 3.545-7.877 7.877s3.545 7.877 7.877 7.877h157.538c4.332 0 7.877-3.545 7.877-7.877 0-4.333-3.545-7.877-7.877-7.877zm-39.385 31.507H244.185c-4.332 0-7.877 3.545-7.877 7.877s3.545 7.877 7.877 7.877h118.154c4.332 0 7.877-3.545 7.877-7.877-.001-4.332-3.545-7.877-7.878-7.877z"
      ></path>
      <path
        fill="#e2574c"
        d="M313.502 43.323l25.206-31.508h55.138L368.64 43.323h-55.138zm-204.8 0l25.206-31.508h55.138L163.84 43.323h-55.138z"
      ></path>
    </svg>
  );
}

export default ContactIcon;
