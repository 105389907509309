import React from "react";

function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="19.5"
      viewBox="972 1561 24 19.5"
    >
      <g data-name="Group 35">
        <path
          fill="#0e3aff"
          fillRule="evenodd"
          d="M996 1563.309c-.893.391-1.844.65-2.835.776a4.893 4.893 0 002.164-2.719 9.833 9.833 0 01-3.12 1.191 4.919 4.919 0 00-8.511 3.365c0 .39.033.765.114 1.122-4.09-.2-7.71-2.16-10.141-5.147a4.954 4.954 0 00-.674 2.487c0 1.704.878 3.214 2.186 4.089a4.859 4.859 0 01-2.223-.606v.054a4.943 4.943 0 003.942 4.835 4.93 4.93 0 01-1.29.161 4.35 4.35 0 01-.932-.084c.638 1.949 2.447 3.382 4.598 3.428a9.886 9.886 0 01-6.1 2.098 9.31 9.31 0 01-1.178-.067 13.852 13.852 0 007.548 2.208c9.054 0 14.004-7.5 14.004-14.001 0-.217-.008-.427-.018-.636a9.816 9.816 0 002.466-2.554z"
          data-name="Path 21"
        ></path>
      </g>
    </svg>
  );
}

export default TwitterIcon;
