import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import './App.css';

import Home from "./components/Home";
import Contact from "./components/Contact";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import Sitemap from "./components/Sitemap";

import Event from "./components/Event/Event";
import Club from "./components/Club/Club";
import League from "./components/League/League";
import Article from "./components/Blog/Article";
import Blog from "./components/Blog/Blog";
import ScrollToTop from "./utils/ScrollToTop";
import RedirectEvent from "./utils/RedirectEvent";

function App() {
  const location = useLocation();

  const isArticleRoute = location.pathname.includes('/blog/');

  return (
    <>
      <ScrollToTop />
      <Navbar isFixed={isArticleRoute} />
      <Routes>
        <Route path="/sitemap" element={<Sitemap />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="/:eventName/event/:groupId" element={<Event />} />
        <Route path="/:clubName/club/:clubId" element={<Club />} />
        <Route path="/:leagueName/league/:leagueId" element={<League />} />

        <Route path="/blog" element={<Blog />} />
        <Route path="/:blogName/blog/:blogId" element={<Article />} />

        <Route path="/" element={<Home />} />

        <Route path="/:eventName/event/:eventid/:currency" element={<RedirectEvent />} />
        <Route path="/en/:eventName/event/:eventid/:currency" element={<RedirectEvent />} />
        <Route path="/en/:eventName/event/:eventid" element={<RedirectEvent />} />
        
        <Route path="*" element={<Navigate to="/" />} />

      </Routes>
      <Footer />
    </>
  );
}

export default App;
