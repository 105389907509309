import React from "react";

function PriceComparisionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: 29 }}
      data-name="Layer 1"
      viewBox="0 0 122.88 122.54"
    >
      <path d="M4.69 0h41.53a4.71 4.71 0 014.69 4.69V46a4.69 4.69 0 01-4.69 4.69H4.69a4.65 4.65 0 01-3.31-1.38l-.09-.09A4.67 4.67 0 010 46V4.69A4.71 4.71 0 014.69 0zm84.75 61.94a26.56 26.56 0 0110.18 2h.07a26.61 26.61 0 0115.25 32.16 26.18 26.18 0 01-2.7 6.11l10.3 11.24a1.27 1.27 0 01-.07 1.8l-7.57 6.9a1.27 1.27 0 01-1.79-.07l-9.86-10.85a26.36 26.36 0 01-6.1 2.74 26.87 26.87 0 01-7.71 1.13 26.51 26.51 0 01-10.17-2h-.07a26.64 26.64 0 01-14.35-34.73v-.07a26.6 26.6 0 0124.59-16.36zm15 11.59a21.38 21.38 0 00-6.89-4.61h-.06a21.22 21.22 0 00-23.07 4.64l-.07.07a21.25 21.25 0 00-4.54 6.83v.06a21.32 21.32 0 00-1.58 8.06 21.26 21.26 0 0029.35 19.62 21.54 21.54 0 006.89-4.61l.07-.07a21.09 21.09 0 004.54-6.83v-.06a21.35 21.35 0 000-16.17 21.34 21.34 0 00-4.62-6.9zM4.69 63.2h41.53a4.71 4.71 0 014.69 4.7v41.34a4.68 4.68 0 01-4.69 4.69H4.69A4.69 4.69 0 010 109.24V67.9a4.71 4.71 0 014.69-4.7zM68.78 0h41.53A4.71 4.71 0 01115 4.69V46a4.71 4.71 0 01-4.69 4.69H68.78A4.71 4.71 0 0164.09 46V4.69a4.69 4.69 0 011.37-3.31l.1-.09A4.67 4.67 0 0168.78 0z"></path>
    </svg>
  );
}

export default PriceComparisionIcon;
