import React from "react";

function VerifiedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="498 883 20 20"
    >
      <path
        fill="#0e3aff"
        fillRule="evenodd"
        d="M517.556 891.74l-1.36-1.58c-.26-.3-.47-.86-.47-1.259v-1.7c0-1.06-.87-1.93-1.93-1.93h-1.7c-.39 0-.96-.209-1.26-.469l-1.58-1.36c-.69-.59-1.82-.59-2.52 0l-1.57 1.37c-.3.25-.87.46-1.26.46h-1.73c-1.06 0-1.93.87-1.93 1.93v1.709c0 .39-.21.95-.46 1.25l-1.351 1.59c-.58.69-.58 1.809 0 2.499l1.35 1.59c.25.3.46.86.46 1.249v1.71c0 1.06.87 1.93 1.93 1.93h1.73c.39 0 .96.209 1.26.469l1.58 1.36c.69.59 1.82.59 2.52 0l1.58-1.36c.3-.26.86-.47 1.26-.47h1.701c1.06 0 1.93-.87 1.93-1.93V897.1c0-.39.21-.96.47-1.26l1.36-1.58c.58-.69.58-1.829-.01-2.519zm-5.4-.63l-4.83 4.83a.75.75 0 01-1.06 0l-2.42-2.42a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l1.89 1.89 4.3-4.3c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06z"
        data-name="Path 24"
      ></path>
    </svg>
  );
}

export default VerifiedIcon;
