import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";

import SearchBox from "./Search/SearchBox";

import LeftIcon from "../icons/left";
import RightIcon from "../icons/right";
import HiddenCostIcon from "../icons/hidden_cost";
import PriceComparisionIcon from "../icons/price_compar";
import CustomerSupportIcon from "../icons/customer_support";

function Home(props) {

    let { lang } = useParams();

    const [leagueScroll, setLeagueScroll] = useState(0);
    const [tournamentScroll, setTournamentScroll] = useState(0);
    const [eventsScroll, setEventsScroll] = useState(0);

    const leagueToScrollRef = useRef(null);
    const tournamentToScrollRef = useRef(null);
    const eventsToScrollRef = useRef(null);

    const [showMobileLeague, setShowMobileLeague] = useState(4);
    const [showMobileTournament, setShowMobileTournament] = useState(4);

    const [showMobileClubs, setShowMobileClubs] = useState(3);

    useEffect(() => {
        if (leagueToScrollRef.current) {
            leagueToScrollRef.current.scrollLeft = leagueScroll;
        }
    }, [leagueScroll]);

    useEffect(() => {
        if (tournamentToScrollRef.current) {
            tournamentToScrollRef.current.scrollLeft = tournamentScroll;
        }
    }, [tournamentScroll]);

    useEffect(() => {
        if (eventsToScrollRef.current) {
            eventsToScrollRef.current.scrollLeft = eventsScroll;
        }
    }, [eventsScroll]);

    const handleLeagueScroll = (direction) => {
        if (direction) {
            setLeagueScroll(leagueScroll + (257) > (257 * ((content.leagues.cards.length - 3) - 1)) ? (257 * ((content.leagues.cards.length - 3) - 1)) : leagueScroll + 257)
        } else {
            setLeagueScroll(leagueScroll - 257 < 0 ? 0 : leagueScroll - 257)
        }
    }
    const handleTournamentScroll = (direction) => {
        if (direction) {
            setTournamentScroll(tournamentScroll + (257) > (257 * ((content.tournaments.cards.length - 3) - 1)) ? (257 * ((content.tournaments.cards.length - 3) - 1)) : tournamentScroll + 257)
        } else {
            setTournamentScroll(tournamentScroll - 257 < 0 ? 0 : tournamentScroll - 257)
        }
    }

    const handleEventsScroll = (direction) => {
        if (direction) {
            setEventsScroll(eventsScroll + (eventsToScrollRef.current.offsetWidth + 20) > ((eventsToScrollRef.current.offsetWidth * (2 - 1)) + 20) ? ((eventsToScrollRef.current.offsetWidth * (2 - 1)) + 20) : eventsScroll + (eventsToScrollRef.current.offsetWidth + 20))
        } else {
            setEventsScroll(eventsScroll - (eventsToScrollRef.current.offsetWidth + 20) < 0 ? 0 : eventsScroll - (eventsToScrollRef.current.offsetWidth + 20))
        }
    }

    const [imageVersion, setImageVersion] = useState(Date.now());

    const content = {
        "clubs": {
            "list": [
                {
                    "country": "England",
                    "title": "Arsenal",
                    "teams_id": "18"
                },
                {
                    "title": "Chelsea",
                    "country": "England",
                    "teams_id": "17"
                },
                {
                    "country": "England",
                    "title": "Liverpool",
                    "teams_id": "7"
                },
                {
                    "title": "Manchester City",
                    "country": "England",
                    "teams_id": "12"
                },
                {
                    "country": "England",
                    "title": "Manchester United",
                    "teams_id": "19"
                },
                {
                    "country": "Germany",
                    "title": "Bayern München",
                    "teams_id": "46"
                },
                {
                    "country": "Germany",
                    "title": "Borussia Dortmund",
                    "teams_id": "41"
                },
                {
                    "title": "Real Madrid",
                    "country": "Spain",
                    "teams_id": "27"
                },
                {
                    "title": "FC Barcelona",
                    "country": "Spain",
                    "teams_id": "21"
                },
                {
                    "country": "Italy",
                    "title": "Juventus",
                    "teams_id": "79"
                },
                {
                    "country": "France",
                    "title": "Paris Saint-Germain",
                    "teams_id": "59"
                },
                {
                    "title": "Ajax",
                    "country": "Netherlands",
                    "teams_id": "586"
                }
            ],
            "title": "Popular Clubs"
        },
        "tournaments": {
            "title": "Popular Tournaments",
            "cards": [
                {
                    "title": "UEFA Nations League",
                    "competition_id": "350"
                },
                {
                    "title": "Champions League",
                    "competition_id": "268"
                },
                {
                    "title": "Europa League",
                    "competition_id": "245"                
                },
                {
                    "title": "Conference League",
                    "competition_id": "446"
                },
                {
                    "title": "FA Cup",
                    "competition_id": "152"
                },
                {
                    "title": "Copa Del Rey",
                    "competition_id": "334"
                },
                {
                    "title": "DFL Supercup",
                    "competition_id": "167"
                }
            ]
        },
        "features": {
            "title": "Features",
            "cards": [
                {
                    "paragraph": "Our platform prioritises transparency; the price you see is the price you pay, ensuring no surprises at checkout.",
                    "title": "No Hidden Costs"
                },
                {
                    "title": "Broad Critical Price Comparisons",
                    "paragraph": "We aggregate prices from multiple ticket sources, enabling you to find the best deals across the board with a single glance."
                },
                {
                    "paragraph": "With our dedicated customer service, rest assured that we're here for you whenever you need assistance or have questions.",
                    "title": "Customer Support"
                }
            ]
        },
        "leagues": {
            "title": "Top League’s",
            "cards": [
                {
                    "title": "Premier league",
                    "image": "premier-league",
                    "competition_id": "2"
                },
                {
                    "title": "Bundesliga",
                    "image": "bundesliga",
                    "competition_id": "1"
                },
                {
                    "title": "La Liga",
                    "image": "la-liga",
                    "competition_id": "3"
                },
                {
                    "title": "Ligue 1",
                    "image": "ligue-1",
                    "competition_id": "5"
                },
                {
                    "image": "serie-a",
                    "title": "Serie A",
                    "competition_id": "4"
                },
                {
                    "title": "Eredivisie",
                    "image": "eredivisie",
                    "competition_id": "196"
                }
            ]
        },
        "events": {
            "buttonLink2": "/uefa-champions-league-tickets/league/268",
            "title": "Popular Events",
            "button2": "Explore",
            "description2": "Champions League is around the corner. Find the best deals for tickets here on Ticketseal!",
            "buttonLink": "/uefa-champions-league-tickets/league/268",
            "cards": [
                {
                    "time": "03:00 PM",
                    "link": "/arsenal-vs-liverpool-fc-tickets/event/5cdf15abf2",
                    "month": "October",
                    "daySuffix": "th",
                    "away_team": "Liverpool FC",
                    "home_team": "Arsenal FC",
                    "home_team_id": "18",
                    "away_team_id": "7",
                    "venue": "Emirates Stadium, London",
                    "day": "26",
                },
                {
                    "day": "27",
                    "daySuffix": "th",
                    "home_team": "Real Madrid",
                    "time": "05:00 PM",
                    "away_team": "Barcelona ",
                    "venue": "Santiago Bernabéu, Madrid",
                    "month": "October",
                    "home_team_id": "27",
                    "away_team_id": "21",
                    "link": "/real-madrid-vs-barcelona-tickets/event/12641ab4c1"
                },
                {
                    "daySuffix": "th",
                    "venue": "Old Trafford Stadium, Manchester",
                    "link": "/manchester-united-vs-chelsea-tickets/event/9d4d36be4a",
                    "home_team": "Manchester United",
                    "month": "November",
                    "home_team_id": "19",
                    "away_team_id": "17",
                    "day": "02",
                    "time": "03:00 PM",
                    "away_team": "Chelsea FC"
                },
                {
                    "day": "23",
                    "daySuffix": "th",
                    "time": "03:00 PM",
                    "link": "/manchester-city-vs-tottenham-hotspur-tickets/event/32c706af42",
                    "home_team_id": "12",
                    "away_team_id": "15",
                    "away_team": "Tottenham Hotspurs",
                    "venue": "Etihad Stadium, Manchester",
                    "home_team": "Manchester City",
                    "month": "November",
                },
                {
                    "home_team": "Bayern Munich",
                    "month": "April",
                    "link": "/bayern-munich-vs-borussia-dortmund-tickets/event/1db096d072",
                    "home_team_id": "46",
                    "away_team_id": "41",
                    "time": "03:30 PM",
                    "daySuffix": "th",
                    "away_team": "Borussia Dortmund",
                    "day": "12",
                    "venue": "Allianz Arena, Munich"
                },
                {
                    "month": "May",
                    "home_team": "RB Leipzig",
                    "away_team_id": "46",
                    "home_team_id": "44",
                    "price": "",
                    "away_team": "Bayern Munich",
                    "venue": "Red Bull Arena, Leipzig",
                    "time": "03:30 PM",
                    "day": "3",
                    "daySuffix": "th",
                    "link": "/rb-leipzig-vs-bayern-munich-tickets/event/15dc468bd0"
                },
                {
                    "day": "11",
                    "home_team_id": "21",
                    "away_team_id": "27",
                    "daySuffix": "th",
                    "home_team": "Barcelona",
                    "time": "05:00 PM",
                    "away_team": "Real Madrid ",
                    "venue": "Estadi Olimpic Lluis Companys, Barcelona",
                    "month": "May",
                    "link": "/barcelona-vs-real-madrid-tickets/event/7072b4aa0e"
                },
                {
                    "away_team": "Crystal Palace",
                    "link": "/liverpool-fc-vs-crystal-palace-tickets/event/c4d6f31d71",
                    "month": "May",
                    "home_team": "Liverpool",
                    "time": "04:00 PM",
                    "day": "25",
                    "home_team_id": "7",
                    "away_team_id": "3",
                    "venue": "Anfield Road, Liverpool",
                    "daySuffix": "th"
                }
            ],
            "button": "Explore",
            "description": "Champions League is around the corner. Find the best deals for tickets here on Ticketseal!"
        },
        "mission": {
            "title": "Mission",
            "html": "<p>TicketSeal is on a mission to redefine the event ticketing experience and provide fans worldwide with the best access to their favorite events.</p><h3>Find the Best Deals</h3><p>Discover the ultimate ticket prices on TicketSeal. Our platform scours multiple sources to bring you the cheapest options and most exciting deals, all conveniently displayed in one place.</p><h3>Your Safety is Priority</h3><p>Rest easy when buying tickets through TicketSeal. We exclusively feature vetted and secure sites, ensuring a worry-free purchasing experience with a 100% ticket guarantee.</p>"
        }
    };


    return (
        <>
            <SearchBox />
            <div className="wrapper">
                <div id="events">
                    <h2>Popular Events</h2>
                    <div className="left_arrow" onClick={() => handleEventsScroll(false)}>
                        <LeftIcon />
                    </div>
                    <div className="right_arrow" onClick={() => handleEventsScroll(true)}>
                        <RightIcon />
                    </div>
                    <div className="cards_list_flex" ref={eventsToScrollRef}>
                        <div className="cards_list_container">
                            <div className="cards_list">
                                {
                                    content.events.cards.map((event, eventIndex) => {
                                        if (eventIndex >= 4) {
                                            return;
                                        }
                                        return (

                                            <Link 
                                                to={event.link}
                                                style={{ backgroundImage: "url('https://ticketseal-blog-bucket.s3.amazonaws.com/image/content_event" + (eventIndex + 1) + "?version=" + imageVersion + "')" }}
                                                className="card_event">
                                                <div className="other_math">
                                                    <div className="other_team">
                                                        <div className="other_team_logo">
                                                            <span>
                                                                <img src={`https://ticketseal-blog-bucket.s3.eu-north-1.amazonaws.com/clubs/club${event.home_team_id}.png`} />
                                                            </span>
                                                        </div>
                                                        <span>{event.home_team}</span>
                                                    </div>
                                                    <div className="other_match_date">
                                                        <span className="other_match_day">{event.day}<span>{event.daySuffix}</span></span>
                                                        <span className="other_match_day">{event.month}</span>
                                                        <span className="other_match_time">{event.time}</span>
                                                        <span>V/S</span>
                                                    </div>
                                                    <div className="vs_small_mobile">V/S</div>
                                                    <div className="other_team">
                                                        <div className="other_team_logo">
                                                            <span>
                                                                <img src={`https://ticketseal-blog-bucket.s3.eu-north-1.amazonaws.com/clubs/club${event.away_team_id}.png`} />
                                                            </span>
                                                        </div>
                                                        <span>{event.away_team}</span>
                                                    </div>
                                                    <div className="other_math_event">
                                                        <span>{event.venue}</span>
                                                        <span>{event.price ? "From " + event.price : ""}</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    }
                                    )
                                }

                            </div>
                        </div>
                        <div className="cards_list_container">
                            <div className="cards_list">
                                {
                                    content.events?.cards.map((event, eventIndex) => {
                                        if (eventIndex < 4) {
                                            return;
                                        }
                                        return (

                                            <Link 
                                                to={event.link}
                                                style={{ backgroundImage: "url('https://ticketseal-blog-bucket.s3.amazonaws.com/image/content_event" + (eventIndex + 1) + "?version=" + imageVersion + "')" }}
                                                className="card_event">
                                                <div className="other_math">
                                                    <div className="other_team">
                                                        <div className="other_team_logo">
                                                            <span>
                                                                <img src={`https://ticketseal-blog-bucket.s3.eu-north-1.amazonaws.com/clubs/club${event.home_team_id}.png`} />
                                                            </span>
                                                        </div>
                                                        <span>{event.home_team}</span>
                                                    </div>
                                                    <div className="other_match_date">
                                                        <span className="other_match_day">{event.day}<span>{event.daySuffix}</span></span>
                                                        <span className="other_match_day">{event.month}</span>
                                                        <span className="other_match_time">{event.time}</span>
                                                        <span>V/S</span>
                                                    </div>
                                                    <div className="vs_small_mobile">V/S</div>
                                                    <div className="other_team">
                                                        <div className="other_team_logo">
                                                            <span>
                                                                <img src={`https://ticketseal-blog-bucket.s3.eu-north-1.amazonaws.com/clubs/club${event.away_team_id}.png`} />
                                                            </span>
                                                        </div>
                                                        <span>{event.away_team}</span>
                                                    </div>
                                                    <div className="other_math_event">
                                                        <span>{event.venue}</span>
                                                        <span>{event.price ? "From " + event.price : ""}</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    }
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div id="tournament">
                    <h2>Popular Tournaments</h2>
                    <div className="left_arrow" onClick={() => handleTournamentScroll(false)}>
                        <LeftIcon />
                    </div>
                    <div className="right_arrow" onClick={() => handleTournamentScroll(true)}>
                        <RightIcon />
                    </div>
                    <div className={"four-cards" + (showMobileTournament ? " four-cards_show_mobile" : "")} ref={tournamentToScrollRef}>
                        {
                            content.tournaments?.cards
                                .map((tournament, tournamentIndex) =>
                                    <Link to={"/" + tournament.title.toLowerCase().replaceAll(" ", "-") + "/league/" + tournament.competition_id} className={tournamentIndex >= showMobileTournament ? "four_card_hide" : ""}>
                                        <div className="card out_title_card">
                                            <img className="tournament_logo" src={`https://ticketseal-blog-bucket.s3.eu-north-1.amazonaws.com/competitions/competition${tournament.competition_id}.png`} />
                                        </div>
                                        <h3>{tournament.title}</h3>
                                    </Link>
                                )
                        }
                        {
                            showMobileTournament < content.tournaments.cards.length &&
                            <div className="btn" onClick={() => setShowMobileTournament(showMobileTournament + 4)} id="popular_events_more">View More</div>
                        }
                    </div>
                </div>
                <div id="clubs">
                    <h2>Popular Clubs</h2>
                    <div className="club_flex">
                        {
                            content.clubs.list.map((club, clubIndex) =>
                                <Link
                                    to={"/" + club.title.toLowerCase().replaceAll(" ", "-") + "-tickets/club/" + club.teams_id}
                                    className={"club_item" + ((clubIndex + 1 > showMobileClubs) ? "" : " show_mobile")}
                                    style={
                                        { backgroundImage: "url('https://ticketseal-blog-bucket.s3.amazonaws.com/image/content_club" + (clubIndex + 1) + "?version=" + imageVersion + "')" }
                                    }
                                >
                                    <div className="club_item_hover">
                                        <span>{club.title}</span>
                                        <span>{club.country}</span>
                                    </div>
                                </Link>
                            )
                        }
                    </div>
                    {
                        showMobileClubs < 12 &&
                        <div className="btn" id="popular_tournaments_more" count="0" onClick={() => setShowMobileClubs(showMobileClubs + 3)}>View More</div>
                    }
                </div>
                <div id="league">
                    <h2>Top League's</h2>
                    <div className="left_arrow" onClick={() => handleLeagueScroll(false)}>
                        <LeftIcon />
                    </div>
                    <div className="right_arrow" onClick={() => handleLeagueScroll(true)}>
                        <RightIcon />
                    </div>
                    <div className={"four-cards" + (showMobileLeague ? " four-cards_show_mobile" : "")} ref={leagueToScrollRef}>
                        {
                            content.leagues.cards
                                .map((league, leagueIndex) =>
                                    <Link to={"/" + league.image + "-tickets/league/" + league.competition_id} className={leagueIndex >= showMobileLeague ? "four_card_hide" : ""}>
                                        <div className="card out_title_card">
                                            <img className="tournament_logo" src={"https://ticketseal-blog-bucket.s3.amazonaws.com/image/content_" + (league.image) + "?version=" + imageVersion} />
                                        </div>
                                        <h3>{league.title}</h3>
                                    </Link>
                                )
                        }
                        {
                            showMobileLeague < content.leagues.cards.length &&
                            <div className="btn" onClick={() => setShowMobileLeague(showMobileLeague + 4)} id="popular_club_more">View More</div>
                        }
                    </div>
                </div>
                <div id="features">
                    <h2>Features</h2>
                    <div className="three-cards">
                        <div className="card">
                            <div className="card_icon">
                                <span>
                                    <HiddenCostIcon />
                                </span>
                            </div>
                            <h3>No Hidden Costs</h3>
                            <p>Our platform prioritises transparency; the price you see is the price you pay, ensuring no surprises at checkout.</p>
                        </div>
                        <div className="card">
                            <div className="card_icon">
                                <span>
                                    <PriceComparisionIcon />
                                </span>
                            </div>
                            <h3>Broad Critical Price Comparisons</h3>
                            <p>We aggregate prices from multiple ticket sources, enabling you to find the best deals across the board with a single glance.</p>
                        </div>
                        <div className="card">
                            <div className="card_icon">
                                <span>
                                    <CustomerSupportIcon />
                                </span>
                            </div>
                            <h3>Customer Support</h3>
                            <p>With our dedicated customer service, rest assured that we're here for you whenever you need assistance or have questions.</p>
                        </div>
                    </div>
                </div>
                <div id="mission" className="text_image">
                    <div className="text">
                        <h2>Mission</h2>
                        <div>
                            <p>TicketSeal is on a mission to redefine the event ticketing experience and provide fans worldwide with the best access to their favorite events.</p>
                            <h3>Find the Best Deals</h3>
                            <p>Discover the ultimate ticket prices on TicketSeal. Our platform scours multiple sources to bring you the cheapest options and most exciting deals, all conveniently displayed in one place.</p>
                            <h3>Your Safety is Priority</h3>
                            <p>Rest easy when buying tickets through TicketSeal. We exclusively feature vetted and secure sites, ensuring a worry-free purchasing experience with a 100% ticket guarantee.</p>
                        </div>
                    </div>
                    <div className="image">
                        <div className="mission_img" style={
                            { backgroundImage: "url('https://ticketseal-blog-bucket.s3.amazonaws.com/image/content_mission?version=" + imageVersion + "')" }
                        }></div>
                    </div>
                </div>
                {/* <div id="partners" className="text_image">
            <div className="text">
                <h2>Our Partners</h2>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste laboriosam tempora quas necessitatibus omnis pariatur veniam ratione natus voluptates, tenetur deleniti id itaque nostrum laborum. Quod voluptas nesciunt enim mollitia! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste laboriosam tempora quas necessitatibus omnis pariatur veniam ratione natus voluptates, tenetur deleniti id itaque nostrum laborum. Quod voluptas nesciunt enim mollitia!</p>
            </div>
            <div className="image">
                <div className="partners_img"></div>
            </div>
        </div>
        <div id="testimonial">
            <h2>Testimonial</h2>
            <div className="testimonial_text">
                <h3>Richael Schumm1</h3>
                <p>Lorem ipsum1 dolor sit amet consectetur adipisicing elit. Animi voluptatum tenetur molestiae facere reiciendis, veniam quibusdam ipsa, soluta pariatur, iure ab perferendis quos enim qui! Earum dignissimos fuga possimus corrupti?</p>
            </div>
            <div className="testimonial_slider">
                <div className="testimonial_item" style="background-image:url('img/testimonial5.png')">
                    <div className="testimonial_action" id="testimonial_left">
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="307.5 1107.5 85 40"><g data-name="Group 74"><path d="m333.877 1107.5-21.377 25 21.377 25" stroke-linejoin="round" stroke-linecap="round" stroke-width="5" stroke="#0e3aff" fill="transparent" data-name="Path 25"/><path d="M382.5 1132.5h-69.401" stroke-linejoin="round" stroke-linecap="round" stroke-width="5" stroke="#0e3aff" fill="transparent" data-name="Path 26"/></g></svg>
                    </div>
                </div>
                <div className="testimonial_item active" id="testimonial_left" style="background-image:url('img/testimonial1.png')"></div>
                <div className="testimonial_item" style="background-image:url('img/testimonial2.png')"></div>
                <div className="testimonial_item" style="background-image:url('img/testimonial3.png')"></div>
                <div className="testimonial_item" style="background-image:url('img/testimonial4.png')">
                    <div className="testimonial_action" id="testimonial_right">
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="1430.5 3822 85 40"><g data-name="CompositeLayer"><path d="m1486.123 3867.5 21.377-25-21.377-25" stroke-linejoin="round" stroke-linecap="round" stroke-width="5" stroke="#0e3aff" fill="transparent" data-name="Path 25"/><path d="M1437.5 3842.5h69.401" stroke-linejoin="round" stroke-linecap="round" stroke-width="5" stroke="#0e3aff" fill="transparent" data-name="Path 26"/></g></svg>
                    </div>
                </div>
            </div>
        </div> */}
            </div>
        </>
    );
}

export default Home;
