import React from "react";

function FollowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M16.462 13.393V9.12a5.195 5.195 0 00-5.189-5.188h-.1A5.195 5.195 0 005.983 9.12v4.272c0 .174-.046.348-.128.495L4.71 16.005c-.376.706.128 1.54.926 1.54H16.8c.797 0 1.301-.852.926-1.54l-1.146-2.117a1.048 1.048 0 01-.129-.495h.01zM8.358 17.545a2.888 2.888 0 002.87 2.622 2.888 2.888 0 002.869-2.622"
      ></path>
      <path
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M13.28 4.326c.028-.128.046-.265.046-.394 0-1.155-.944-2.099-2.099-2.099-1.155 0-2.1.944-2.1 2.1 0 .137.01.274.047.393"
      ></path>
    </svg>
  );
}

export default FollowIcon;
