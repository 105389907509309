import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SearchBox from '../Search/SearchBox';
import BreadcrumbsIcon from '../../icons/breadcrumbs';
import ArrowDownIcon from '../../icons/arrow_down';
import ArrowBottomIcon from '../../icons/arrow_bottom';
import FollowIcon from '../../icons/follow';
import { useCurrency } from '../../utils/currencyUtils';
import ClubAlertPopup from './ClubAlertPopup';
import { Helmet } from 'react-helmet';

function Club() {
  const navigate = useNavigate();
  const { currency, currencyCharacter, handleCurrencyChange, convertPrice } = useCurrency();
  const { clubId } = useParams();

  const [data, setData] = useState([]);
  const [clubInfo, setClubInfo] = useState(null);
  const [competitions, setCompetitions] = useState([
    { name: 'All leagues', id: null }
  ]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [viewMoreLoading, setViewMoreLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCompetition, setSelectedCompetition] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const dropdownRefs = [useRef(null), useRef(null), useRef(null)];
  const [isDropdownActive, setIsDropdownActive] = useState([false, false, false]);

  const months = [
    { name: 'All months', number: null },
    { name: 'January', number: '01' },
    { name: 'February', number: '02' },
    { name: 'March', number: '03' },
    { name: 'April', number: '04' },
    { name: 'May', number: '05' },
    { name: 'June', number: '06' },
    { name: 'July', number: '07' },
    { name: 'August', number: '08' },
    { name: 'September', number: '09' },
    { name: 'October', number: '10' },
    { name: 'November', number: '11' },
    { name: 'December', number: '12' }
  ];

  const [alertOpen, setAlertOpen] = useState(false);

  const fetchAndProcessData = (offsetInput, competitionInput, monthInput) => {
    const payload = {
      clubId,
      offset: offsetInput,
      competition_id: competitionInput,
      month: monthInput
    };

    fetch('https://7sq9r5uhnl.execute-api.eu-north-1.amazonaws.com/public/api/club', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        if (!response.ok) {
          // throw new Error('Network response was not ok');
          navigate("/");
        }
        return response.json();
      })
      .then(jsonData => {
        console.log(jsonData);

        const data = JSON.parse(jsonData.body);

        setClubInfo(data.team_info);
        setCompetitions([
          { name: 'All leagues', id: null },
          ...data.competitions.map(competition => ({
            name: competition.competition_name,
            id: competition.competition_id
          }))
        ]);

        const processedData = data.events.map(event => {
          return {
            group_id: event.group_id,
            competition_name: event.competition_name,
            home_team_id: event.home_team_id,
            home_team: event.home_team,
            away_team_id: event.away_team_id,
            away_team: event.away_team,
            min_price: getMinPrice(event.prices),
            date: event.event_date,
            time: event.event_time,
            location: {
              venue: event.venue,
              city: event.city,
              country: event.country
            }
          };
        });

        if (offsetInput === 0) {
          setData(processedData);
        } else {
          setData(prevData => [...prevData, ...processedData]);
        }

        if (processedData.length === 4) {
          setOffset(offsetInput + 4);
        } else {
          setOffset(-1);
        }

        setLoading(false);
        setResultsLoading(false);
        setViewMoreLoading(false);
      })
      .catch(error => {
        navigate("/")
        setError(error);
        setLoading(false);
        setResultsLoading(false);
        setViewMoreLoading(false);
      });
  };

  useEffect(() => {
    setData([]);
    setClubInfo(null);
    setCompetitions([{ name: 'All leagues', id: null }]);
    setOffset(0);
    setLoading(true);
    fetchAndProcessData(0, selectedCompetition, selectedMonth);
  }, [clubId]);

  const handleFilterChange = (competition, month) => {
    setData([]);
    setOffset(0);
    setResultsLoading(true);
    fetchAndProcessData(0, competition, month);
  };

  const handleViewMore = () => {
    setViewMoreLoading(true);
    fetchAndProcessData(offset, selectedCompetition, selectedMonth);
  }

  const getMinPrice = (array) => {
    const nonNullValues = array.filter(value => value !== null);
    if (nonNullValues.length === 0) {
      return null;
    }
    const minNonNullValue = nonNullValues
      .map(Number)
      .reduce((min, current) => current < min ? current : min, Infinity);

    return minNonNullValue;
  };

  const convertTime = (timeString) => {
    let [hours, minutes] = timeString.split(":");
    hours = parseInt(hours, 10);
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    if (hours && minutes && period)
      return `${hours}:${minutes} ${period}`;
  };

  const formatDateToMonthDay = (dateString) => {
    const date = new Date(dateString);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = months[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}`;
  };

  const getDayOfWeek = (dateString) => {
    const date = new Date(dateString);
    const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const dayOfWeek = daysOfWeek[date.getDay()];
    return dayOfWeek;
  };

  const makePathFriendly = (str) => {
    return str
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, '')
      .trim()
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
  };

  const toggleDropdown = (index) => {
    setIsDropdownActive(prevState => prevState.map((active, i) => i === index ? !active : active));
  };

  const handleClickOutside = (event) => {
    dropdownRefs.forEach((ref, index) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsDropdownActive(prevState => prevState.map((active, i) => i === index ? false : active));
      }
    });
  };

  const handleCurrencyClick = (newCurrency) => {
    handleCurrencyChange(newCurrency);
    toggleDropdown(0); // Close the currency dropdown
  };

  const handleCompetitionClick = (competitionId) => {
    setSelectedCompetition(competitionId);
    handleFilterChange(competitionId, selectedMonth);
    toggleDropdown(1); // Close the competition dropdown
  };

  const handleMonthClick = (monthNumber) => {
    setSelectedMonth(monthNumber);
    handleFilterChange(selectedCompetition, monthNumber);
    toggleDropdown(2); // Close the month dropdown
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (loading) {
    return (
      <>
        <div className="search_box_skeleton skeleton_after"></div>
        <div className="wrapper wrapper_skeleton">
          <div className="breadcrumbs_skeleton skeleton_after"></div>
          <div className="club_league_header_skeleton skeleton_after"></div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cheap {clubInfo.team_name} Football Tickets in 2024</title>
        <meta name="description" content={`Looking for the best deals for ${clubInfo.team_name} Football tickets? Compare trusted platforms for cheap tickets. Find the best offers, compare prices, and save now!`} />
      </Helmet>
      <SearchBox searchText={<>Find the best deals on<br />{clubInfo.team_name} Tickets</>} />
      <div className="wrapper">
        <div className="breadcrumbs">
          <span className="breadcrumbs_text">Clubs</span>
          <BreadcrumbsIcon />
          <span className="breadcrumbs_text">{clubInfo.team_name}</span>
        </div>

        <div className="club_header">
          <div
            className="club_header_img"
            style={{ backgroundImage: `url('https://ticketseal-blog-bucket.s3.eu-north-1.amazonaws.com/clubs/club${clubId}.png')` }}
          />
          <div className="club_header_title">
            <span>{clubInfo.team_name}</span>
            <div className="club_header_info">
              <span>{clubInfo.country_name}</span>
              <div className="club_header_info_btn">Compare</div>
            </div>
          </div>
          <div className="club_header_action">
            <p>Receive notifications for all games of this team</p>
            <div className="club_header_btn" onClick={() => setAlertOpen(true)}>
              <FollowIcon />
              <span>Follow</span>
            </div>
          </div>
        </div>

        <div className="event_description">
          <h3>Where to get {clubInfo.team_name} Tickets</h3>
          <p>Tickets for {clubInfo.team_name} games are available with several matches coming up. Don’t miss the chance to experience a {clubInfo.team_name} game and be part of the action!
          </p>
        </div>

        <div className="event_post_listing">
          <div className="event_post_listing_header">
            <h2>Upcoming {clubInfo.team_name} events</h2>

            <div className="event_dropdown_container" ref={dropdownRefs[0]}>
              <div className="club_listing_dropdown" onClick={() => toggleDropdown(0)}>
                {currency === "EUR" && "€"}
                {currency === "USD" && "$"}
                {currency === "GBP" && "£"}
                <ArrowDownIcon />
              </div>
              <div className={`event_dropdown_list ${isDropdownActive[0] ? 'active' : ''}`}>
                <span onClick={() => handleCurrencyClick("EUR")}>EUR</span>
                <span onClick={() => handleCurrencyClick("USD")}>USD</span>
                <span onClick={() => handleCurrencyClick("GBP")}>GBP</span>
              </div>
            </div>

            <div className="event_dropdown_container event_dropdown_leagues" ref={dropdownRefs[1]}>
              <div className="club_listing_dropdown" onClick={() => toggleDropdown(1)}>
                <span>{competitions.find(comp => comp.id === selectedCompetition)?.name || 'All Competitions'}</span>
                <ArrowBottomIcon />
              </div>
              <div className={`event_dropdown_list ${isDropdownActive[1] ? 'active' : ''}`}>
                {competitions.map(competition => (
                  <span key={competition.id} onClick={() => handleCompetitionClick(competition.id)}>
                    {competition.name}
                  </span>
                ))}
              </div>
            </div>

            <div className="event_dropdown_container event_dropdown_month" ref={dropdownRefs[2]}>
              <div className="club_listing_dropdown" onClick={() => toggleDropdown(2)}>
                <span>{months.find(month => month.number === selectedMonth)?.name || 'All Months'}</span>
                <ArrowBottomIcon />
              </div>
              <div className={`event_dropdown_list ${isDropdownActive[2] ? 'active' : ''}`}>
                {months.map(month => (
                  <span key={month.number} onClick={() => handleMonthClick(month.number)}>
                    {month.name}
                  </span>
                ))}
              </div>
            </div>
          </div>

          {resultsLoading ? (
            <div className="league_result_list_skeleton_container">
              <div className="league_result_list_skeleton skeleton_after" />
              <div className="skeleton_fading_gradient"></div>
            </div>
          ) : (
            <div className="league_result_list">
              {data.map(event => (
                <Link
                  key={event.group_id}
                  to={`/${makePathFriendly(event.home_team)}-vs-${makePathFriendly(event.away_team)}-tickets/event/${event.group_id}`}
                  className="league_result_item"
                >
                  <div className="league_result_date">
                    <span>{formatDateToMonthDay(event.date)}</span>
                    <span>{getDayOfWeek(event.date)}</span>
                    {
                      event.time && convertTime(event.time) &&
                      <span>{convertTime(event.time)}</span>
                    }
                  </div>
                  <div className="league_divider"></div>
                  <div className="league_result_info">
                    <h2>{event.home_team} vs {event.away_team}</h2>
                    <span>
                      {event.location?.venue}
                      {(event.location?.venue && (event.location?.city || event.location?.country)) && " in "}
                      {event.location?.city}
                      {(event.location?.city && event.location?.country) && ", "}{event.location?.country}
                    </span>
                  </div>
                  <Link
                    to={`/${makePathFriendly(event.home_team)}-vs-${makePathFriendly(event.away_team)}-tickets/event/${event.group_id}`}
                    className="league_result_btn">
                    {event.min_price ? (`From ${currencyCharacter}${parseFloat(convertPrice(event.min_price)).toFixed(2)}`) : "Check tickets"}
                  </Link>
                </Link>
              ))}
            </div>
          )}

          <div className="event_post_listing_action">
            {offset !== -1 && !resultsLoading && (
              <span className={"event_post_listing_btn" + (viewMoreLoading ? " event_post_listing_btn_loading" : "")} onClick={() => !viewMoreLoading ? handleViewMore() : null}>View More</span>
            )}
          </div>
        </div>
      </div>

      {
        alertOpen &&
        <ClubAlertPopup
          teamName={clubInfo.team_name}
          teamId={clubId}
          setAlertOpen={setAlertOpen}
        />
      }

    </>
  );
}

export default Club;
