import React from "react";

function BackNavIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 -960 960 960"
    >
      <path d="M400-80L0-480l400-400 71 71-329 329 329 329-71 71z"></path>
    </svg>
  );
}

export default BackNavIcon;
