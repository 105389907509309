import React from "react";

function ArrowBottomIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#000129"
        d="M14.375 16.364L8.055 10 6.25 11.818 14.375 20l8.125-8.182L20.695 10l-6.32 6.364z"
      ></path>
    </svg>
  );
}

export default ArrowBottomIcon;
