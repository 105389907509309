import React, { useState, useEffect, useRef  } from "react";
import { Link, useParams } from "react-router-dom";

import HamburgerIcon from "../icons/hamburger";
import CloseIcon from "../icons/close";
import BackNavIcon from "../icons/back_nav";
import Logo from "../images/ticketseal_logo.png";
import LogoStories from "../images/ticketseal_blog_logo.png";
import GlobeIcon from "../icons/globe";

function Navbar({ isFixed }) {

    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  return (
    <>
        <div className={"navbar" + (isFixed ? " fixed" : "")}>
            <div className="wrapper">
                <span className="navbar_mobile" onClick={() => setIsMobileNavOpen(true)}>
                    <HamburgerIcon />
                </span>
                <a className="logo_container" href={"/"}>
                    <img src={Logo} alt="logo" />
                </a>
                <div className="nav_links">
                    <Link to="/blog">Blog</Link>
                    <a href="/#mission" >About us</a>
                    <Link to="/contact">Help & Support</Link>
                </div>
            </div>
            <div className={"nav_dropdown" + (isMobileNavOpen ? " active" : "")} id="dropdown_menu">
                <div className="search_dropdown_header">
                    <span>Menu</span>
                    <span onClick={() => setIsMobileNavOpen(false)} style={{marginLeft: "auto"}}>
                        <CloseIcon />
                    </span>
                </div>
                <div className="wrapper">
                    <div className="nav_drop_section">
                        <Link href="/blog" className="nav_drop_link">Blog</Link>
                        <a href="/#mission" onClick={() => setIsMobileNavOpen(false)} className="nav_drop_link" >About us</a>
                        <Link href="/contact" className="nav_drop_link" >Help & Support</Link>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default Navbar;
