import React from "react";

function LeftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.004"
      height="13.945"
      viewBox="10588 1543 8.004 13.945"
    >
      <path
        fillRule="evenodd"
        d="M10588 1550.004a1 1 0 01.29-.71l6-6a1.004 1.004 0 011.42 1.42l-5.3 5.29 5.29 5.29a1 1 0 01-1.41 1.41l-6-6a1 1 0 01-.29-.7z"
        data-name="16"
      ></path>
    </svg>
  );
}

export default LeftIcon;
