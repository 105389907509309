import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import InstagramIcon from "../icons/instagram";
import TwitterIcon from "../icons/twitter";
import FacebookIcon from "../icons/facebook";
import LinkedinIcon from "../icons/linkedin";

import Logo from "../images/ticketseal_logo.png";
import FooterIcon from "../icons/footer";
import PlusIcon from "../icons/plus";
import MinusIcon from "../icons/minus";

function Footer(props) {

    const [openQuestions, setOpenQuestions] = useState([]);

    const toggleAnswer = (index) => {
        setOpenQuestions(prevOpenQuestions =>
            prevOpenQuestions.includes(index)
                ? prevOpenQuestions.filter(i => i !== index) // Close if open
                : [...prevOpenQuestions, index] // Open if closed
        );
    };

    return (
        <>
            <div className="pre_footer">
                <FooterIcon />
                <div className="pre_footer_wrapper">
                    <div className="wrapper">
                        <p className="pre_footer_text">Ticketseal always attempts to get accurate pricing, however, <span>prices are not guaranteed.</span></p>
                        <p className="pre_footer_text">Here's why:</p>
                        <div className="pre_footer_item">
                            <div className="event_faq_question" onClick={() => toggleAnswer(0)}>
                                <span>Ticketseal is not the seller</span>
                                <div className="event_faq_icon">
                                    {openQuestions.includes(0) ? <MinusIcon /> : <PlusIcon />}
                                </div>
                            </div>
                            <p className={`event_faq_answer ${openQuestions.includes(0) ? 'open' : ''}`}>
                                At Ticketseal, we don’t set the prices, so we can’t guarantee the deals offered by our partners.
                            </p>
                        </div>
                        <div className="pre_footer_item">
                            <div className="event_faq_question" onClick={() => toggleAnswer(1)}>
                                <span>We aggregate tonnes of data for you</span>
                                <div className="event_faq_icon">
                                    {openQuestions.includes(1) ? <MinusIcon /> : <PlusIcon />}
                                </div>
                            </div>
                            <p className={`event_faq_answer ${openQuestions.includes(1) ? 'open' : ''}`}>
                                With Ticketseal, you can quickly and easily compare prices from popular platforms active in the resale market all at once.
                            </p>
                        </div>
                        <div className="pre_footer_item">
                            <div className="event_faq_question" onClick={() => toggleAnswer(2)}>
                                <span>Why aren't prices always precise?</span>
                                <div className="event_faq_icon">
                                    {openQuestions.includes(2) ? <MinusIcon /> : <PlusIcon />}
                                </div>
                            </div>
                            <p className={`event_faq_answer ${openQuestions.includes(2) ? 'open' : ''}`}>
                                Prices for football tickets fluctuate frequently. Additionally, multiple people might be trying to book the same deal simultaneously, which can lead to the price you intended to pay no longer being available. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer" onClick={() => console.log(props.content)}>
                <div className="wrapper">
                    <div className="footer_link_lists">
                        <div className="footer_link_item">
                            <div className="footer_link_list">
                                <h4>Learn More Stuff</h4><a href="/#mission">About us</a>
                                <Link to="/contact">Contact Us</Link>
                                <Link to="/sitemap">Sitemap</Link>
                            </div>
                            <div className="footer_link_list">
                                <h4>Top Competitions</h4>
                                <Link to="/english-premier-league-tickets/league/2">English Premier League Tickets</Link>
                                <Link to="/spanish-la-liga-tickets/league/3">Spanish La Liga Tickets</Link>
                                <Link to="/french-ligue-1-tickets/league/5">French Ligue 1 Tickets</Link>
                                <Link to="/german-bundesliga-tickets/league/1">German Bundesliga Tickets</Link>
                                <Link to="/italian-serie-a-tickets/league/4">Italian Serie A Tickets</Link>
                                <Link to="/belgium-league-tickets/league/68">Belgium League Tickets</Link>
                                <Link to="/dutch-eredivisie-tickets/league/196">Dutch Eredivisie Tickets</Link>
                                <Link to="/uefa-champions-league-tickets/league/268">UEFA Champions League Tickets</Link>
                                <Link to="/europa-league-tickets/league/245">UEFA Europa League Tickets</Link>
                                <Link to="/conference-league/league/446">UEFA Conference League Tickets</Link>
                                <Link to="/uefa-nations-league-tickets/league/350">UEFA Nations League Tickets</Link>
                            </div>
                        </div>
                        <div className="footer_link_item">
                            <div className="footer_link_list">
                                <h4>Top Football Clubs</h4>
                                <Link to="/liverpool-fc-tickets/club/7">Liverpool Tickets</Link>
                                <Link to="/chelsea-tickets/club/17/">Chelsea Tickets</Link>
                                <Link to="/paris-saint-germain-tickets/club/59">Paris Saint Germain (PSG) Tickets</Link>
                                <Link to="/barcelona-tickets/club/21">Barcelona Tickets</Link>
                                <Link to="/manchester-united-tickets/club/19">Manchester United Tickets</Link>
                                <Link to="/arsenal-tickets/club/18">Arsenal Tickets</Link>
                                <Link to="/tottenham-hotspur-tickets/club/15">Tottenham Hotspur Tickets</Link>
                                <Link to="/manchester-city-tickets/club/12">Manchester City Tickets</Link>
                                <Link to="/real-madrid-tickets/club/27">Real Madrid Tickets</Link>
                                <Link to="/atletico-madrid-tickets/club/26">Atletico Madrid Tickets</Link>
                                <Link to="/sevilla-tickets/club/23">Sevilla Tickets</Link>
                                <Link to="/lyon-tickets/club/61">Olympique Lyon Tickets</Link>
                                <Link to="/monaco-tickets/club/60">Monaco Tickets</Link>
                                <Link to="/lille-tickets/club/74">Lille Tickets</Link>
                                <Link to="/marseille-tickets/club/68">Olympique de Marseille (OM) Tickets</Link>
                                <Link to="/bayern-munich-tickets/club/46">Bayern Munich Tickets</Link>
                                <Link to="/dortmund-tickets/club/41">Dortmund Tickets</Link>
                                <Link to="/juventus-fc-tickets/club/79">Juventus FC Tickets</Link>
                                <Link to="/ac-milan-tickets/club/85">AC Milan Tickets</Link>
                                <Link to="/as-roma-tickets/club/90">AS Roma Tickets</Link>
                                <Link to="/inter-milan-tickets/club/81">Inter Milan Tickets</Link>
                                <Link to="/ajax-tickets/club/586">Ajax Tickets</Link>
                                <Link to="/west-ham-united-tickets/club/14">West Ham United Tickets</Link>
                            </div>
                        </div>
                        <div className="footer_link_item">
                            <div className="footer_link_list">
                                <h4>Top Football Matches</h4>
                                <Link to="/arsenal-vs-liverpool-fc-tickets/event/5cdf15abf2" >Arsenal vs Liverpool Tickets</Link>
                                <Link to="/liverpool-fc-vs-manchester-united-tickets/event/fc64bdfb5f">Liverpool vs Manchester United Tickets</Link>
                                <Link to="/manchester-united-vs-liverpool-fc-tickets/event/008aa48d84">Manchester United vs Liverpool Tickets</Link>
                                <Link to="/chelsea-vs-liverpool-fc-tickets/event/b9fb93f419">Chelsea vs Liverpool Tickets</Link>
                                <Link to="/arsenal-vs-chelsea-tickets/event/f5c356e49e">Arsenal vs Chelsea Tickets</Link>
                                <Link to="/bayern-munich-vs-borussia-dortmund-tickets/event/1db096d072">Bayern Munich vs Borussia Dortmund Tickets</Link>
                                <Link to="/arsenal-vs-tottenham-hotspur-tickets/event/7e07e347ce">Arsenal vs Tottenham Hotspur Tickets</Link>
                                <Link to="/ac-milan-vs-inter-tickets/event/89b25df4ee">AC Milan vs Inter Tickets</Link>
                                <Link to="/manchester-united-vs-manchester-city-tickets/event/655f5fc861">Manchester United vs Manchester City Tickets</Link>
                                <Link to="/atletico-madrid-vs-real-madrid-tickets/event/05b611959f">Atletico Madrid vs Real Madrid Tickets</Link>
                                <Link to="/barcelona-vs-real-madrid-tickets/event/7072b4aa0e">FC Barcelona vs Real Madrid Tickets</Link>
                            </div>
                        </div>

                        <div className="footer_link_item">
                            <div className="footer_link_list">
                                <h4>Top Cities</h4><Link to="/London/events/">London Tickets</Link>
                                <a href="#">Berlin Tickets</a>
                                <a href="#">Manchester Tickets</a>
                                <a href="#">Liverpool Tickets</a>
                                <a href="#">Glasgow Tickets</a>
                                <a href="#">Barcelona Tickets</a>
                                <a href="#">Madrid Tickets</a>
                                <a href="#">Amsterdam Tickets</a>
                                <a href="#">Munich Tickets</a>
                                <a href="#">Hamburg Tickets</a>
                                <a href="#">Cologne Tickets</a>
                                <a href="#">Milano Tickets</a>
                                <a href="#">Paris Tickets</a>
                                <a href="#">Lyon Tickets</a>
                                <a href="#">Budapest Tickets</a>
                                <a href="#">Rome Tickets</a>
                                <a href="#">Lisbon Tickets</a>
                                <a href="#">Copenhagen Tickets</a>
                                <a href="#">Zurich Tickets</a>
                                <a href="#">Helsinki Tickets</a>
                            </div>
                        </div>
                    </div>
                    

                    <div className="copyright">
                        <a className="logo_container" href="/">
                            <img src={Logo} alt="logo" />
                        </a>
                        {/*
                        <div className="footer_links">
                            <a href="#">Privacy Policy</a>
                            <a href="#">Terms & Conditions</a>
                        </div>
                        */}
                        <span>© 2024 Ticketseal. All rights reserved.</span>
                        <div className="footer_social">
                        <a href="https://www.instagram.com/ticketseal_official" target="_blank" rel="noopener noreferrer">
                            <InstagramIcon />
                        </a>
                        <a href="https://x.com/TicketSeal_hi" target="_blank" rel="noopener noreferrer">
                            <TwitterIcon />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61560516724020" target="_blank" rel="noopener noreferrer">
                            <FacebookIcon />
                        </a>
{/*                        
                        <a href="https://www.linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer">
                            <LinkedinIcon />
                        </a>
*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
