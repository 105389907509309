import React from "react";

function RightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.004"
      height="13.945"
      viewBox="20.996 18 8.004 13.945"
    >
      <path
        fillRule="evenodd"
        d="M29 25.004a1 1 0 00-.29-.71l-6-6a1.004 1.004 0 00-1.42 1.42l5.3 5.29-5.29 5.29a1 1 0 001.41 1.41l6-6a1 1 0 00.29-.7z"
        data-name="16"
      ></path>
    </svg>
  );
}

export default RightIcon;
