import React, { useState, useEffect } from 'react';

import BlogBar from "../../images/blog_bar.png";
import ReadMoreIcon from "../../icons/readmore";
import ArticleDateIcon from "../../icons/article_date";

import axios from "axios";
import { Link } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';

import { Helmet } from 'react-helmet';

function Blog() {
    const [articles, setArticles] = useState([]);
    const [articlesLoading, setArticlesLoading] = useState(true);

    useEffect(() => {
        fetchArticles();
    }, []);

    const fetchArticles = async () => {
        try {
            const response = await axios.get('https://7sq9r5uhnl.execute-api.eu-north-1.amazonaws.com/public/api/blog');
            const data = JSON.parse(response.data.body);

            setArticles(data);
            setArticlesLoading(false);
        } catch (error) {
            console.error('Error fetching articles:', error);
            setArticlesLoading(false);
        }
    };

    const formatDate = (dateStr) => {
        const [year, month, day] = dateStr.split('-');
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        return `${months[parseInt(month) - 1]} ${parseInt(day)}, ${year}`;
    };

    const slugify = (text) => {
        return text.toLowerCase().replace(/ /g, '-');
    };

    const headerArticles = articles.filter(article => article.card1 || article.card2 || article.card3);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ticketseal.com | Blog</title>
                <meta name="description" content="Discover the latest insights on football tickets, top buying tips, pricing trends, and more in the online Ticketseal magazine." />
            </Helmet>
            <div id="blog_header">
                {articlesLoading ? (
                    <div className="blog_header_flex">
                        <div className="blog_header_cards">
                            <div className="blog_header_subcard blog_header_skeleton"></div>
                            <div className="blog_header_card_flex">
                                <div className="blog_header_readmore blog_header_skeleton"></div>
                                <div className="blog_header_flex_subcard blog_header_skeleton"></div>
                            </div>
                        </div>
                        <div className="blog_header_card blog_header_skeleton"></div>
                    </div>
                ) : (
                    <div className="blog_header_flex">
                        <div className="blog_header_cards">
                            {headerArticles.length > 0 && headerArticles.map((article, index) => {
                                if (article.card1) {
                                    return (
                                        <Link
                                            key={index}
                                            to={`/${slugify(article.title)}/blog/${article.blog_id}`}
                                            className={article.card1 ? "blog_header_subcard" : "blog_header_flex_subcard"}
                                            style={{ backgroundImage: `url('https://ticketseal-blog-bucket.s3.amazonaws.com/header/${article.blog_id}')` }}
                                        >
                                            <div className="blog_header_tag" style={{ backgroundColor: article.category_color }}>{article.category}</div>
                                            <div className="blog_header_card_content">
                                                <h2>{article.title}</h2>
                                                <p>
                                                    <span>{article.author}</span>
                                                    <span>|</span>
                                                    <span>{formatDate(article.creation_date)}</span>
                                                </p>
                                            </div>
                                        </Link>
                                    );
                                }
                                return null;
                            })}
                            <div className="blog_header_card_flex">
                                <div className="blog_header_readmore">
                                    <span>Read more</span>
                                    <ReadMoreIcon />
                                </div>
                                {headerArticles.map((article, index) => {
                                    if (article.card2) {
                                        return (
                                            <Link
                                                key={index}
                                                to={`/${slugify(article.title)}/blog/${article.blog_id}`}
                                                className="blog_header_flex_subcard"
                                                style={{ backgroundImage: `url('https://ticketseal-blog-bucket.s3.amazonaws.com/header/${article.blog_id}')` }}
                                            >
                                                <div className="blog_header_tag" style={{ backgroundColor: article.category_color }}>{article.category}</div>
                                                <div className="blog_header_card_content">
                                                    <h2>{article.title}</h2>
                                                    <p>
                                                        <span>{article.author}</span>
                                                        <span>|</span>
                                                        <span>{formatDate(article.creation_date)}</span>
                                                    </p>
                                                </div>
                                            </Link>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                        {headerArticles.length > 0 && (
                            <Link
                                to={`/${slugify(headerArticles.find(article => article.card3).title)}/blog/${headerArticles.find(article => article.card3).blog_id}`}
                                className="blog_header_card"
                                style={{ backgroundImage: `url('https://ticketseal-blog-bucket.s3.amazonaws.com/header/${headerArticles.find(article => article.card3).blog_id}')` }}
                            >
                                <div className="blog_header_tag" style={{ backgroundColor: headerArticles.find(article => article.card3).category_color }}>{headerArticles.find(article => article.card3).category}</div>
                                <div className="blog_header_card_content">
                                    <h2>{headerArticles.find(article => article.card3).title}</h2>
                                    <p>
                                        <span>{headerArticles.find(article => article.card3).author}</span>
                                        <span>|</span>
                                        <span>{formatDate(headerArticles.find(article => article.card3).creation_date)}</span>
                                    </p>
                                </div>
                            </Link>
                        )}
                    </div>
                )}
            </div>
            <img src={BlogBar} className="blog_bar" />
            <div id="articles_list">
                <div className="wrapper">
                    <h2>Our Articles</h2>
                    <div className="article_flex">
                        {articlesLoading ? (
                            <>
                                <div className="article_item article_item_skeleton"><div className="article_img"></div></div>
                                <div className="article_item article_item_skeleton"><div className="article_img"></div></div>
                                <div className="article_item article_item_skeleton"><div className="article_img"></div></div>
                            </>
                        ) : (
                            articles.map(article => (
                                <Link
                                    key={article.blog_id}
                                    to={`/${slugify(article.title)}/blog/${article.blog_id}`}
                                    className="article_item"
                                >
                                    <div className="article_img" style={{ backgroundImage: `url('https://ticketseal-blog-bucket.s3.amazonaws.com/header/${article.blog_id}')` }}>
                                        <div className="blog_header_tag" style={{ backgroundColor: article.category_color }}>{article.category}</div>
                                    </div>
                                    <div className="article_item_container">
                                        <div className="article_blog_date">
                                            <ArticleDateIcon />
                                            <span>{formatDate(article.creation_date)}</span>
                                        </div>
                                        <h3>
                                            <LinesEllipsis
                                                text={article.title}
                                                maxLine='2'
                                                ellipsis=''
                                                trimRight
                                                basedOn='letters'
                                            />
                                        </h3>
                                        <p>
                                            <LinesEllipsis
                                                text={article.description}
                                                maxLine='4'
                                                ellipsis=''
                                                trimRight
                                                basedOn='letters'
                                            />
                                        </p>
                                        <div className="article_btn">Read More</div>
                                    </div>
                                </Link>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Blog;
