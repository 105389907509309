import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const RedirectEvent = () => {
    const { eventid } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGroupId = async () => {
            try {
                const response = await axios.post('https://7sq9r5uhnl.execute-api.eu-north-1.amazonaws.com/public/api/redirect', {
                    event_id: eventid
                });

                const responseBody = JSON.parse(response.data.body);

                const { group_id, home_team, away_team } = responseBody;

                if (group_id) {
                    // If group_id exists, redirect to the event page
                    navigate(`/${home_team.toLowerCase().replaceAll(" ","-")}-vs-${away_team.toLowerCase().replaceAll(" ","-")}/event/${group_id}`, { replace: true });
                } else {
                    // If no group_id found, redirect to home
                    navigate('/', { replace: true });
                }
            } catch (error) {
                // Handle error and redirect to home
                console.error('Error fetching group_id:', error);
                navigate('/', { replace: true });
            }
        };

        fetchGroupId();
    }, [eventid, navigate]);

    // Optional: Add a loading state or a spinner while waiting for the redirect
    return <>
        <div className="search_box_skeleton skeleton_after"></div>
        <div className="wrapper wrapper_skeleton">
            <div className="breadcrumbs_skeleton skeleton_after"></div>
            <div className="event_vs_skeleton skeleton_after"></div>
        </div>
    </>;
};

export default RedirectEvent;
