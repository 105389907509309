import React, { useState } from 'react';
import axios from 'axios';
import StubhubIcon from "../../images/stubhubfavicon.png";
import ViagogoIcon from "../../images/viagogofavicon.png";
import SportsEvents365Icon from "../../images/sportsevents365favicon.png";
import LiveFootballTicketsIcon from "../../images/livefootballticketsfavicon.png";
import WowIcon from "../../images/wowfavicon.png";
import FootballTicketNetIcon from "../../images/footballticketnetfavicon.jpg";
import P1Icon from "../../images/p1favicon.png";
import CloseIcon from '../../icons/close';
import CheckIcon from '../../icons/check';

function EventAlertPopup({ eventName, platformName, eventDate, eventTime, groupId, setAlertOpen }) {
    const [alertEmail, setAlertEmail] = useState('');
    const [alertError, setAlertError] = useState(false);
    const [alertLoading, setAlertLoading] = useState(false);
    const [alertSend, setAlertSend] = useState(false);

    const formatDate = (dateStr) => {
        if (!dateStr) return '';
        const date = new Date(dateStr);
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    const formatTime = (timeStr) => {
        if (!timeStr) return '';
        const [hour, minute] = timeStr.split(':');
        const period = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}:${minute} ${period}`;
    };

    const handleAlert = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(alertEmail)) {
            setAlertError(true);
            return;
        }

        setAlertError(false);
        setAlertLoading(true);

        const platform = platformName || 'event';

        try {
            const response = await axios.post('https://7sq9r5uhnl.execute-api.eu-north-1.amazonaws.com/public/api/alert', {
                email: alertEmail,
                id: groupId,
                value: eventName,
                type: platform
            });

            if (response.status === 200) {
                setAlertSend(true);
            }
        } catch (error) {
            console.error('Error sending alert:', error);
        } finally {
            setAlertLoading(false);
        }
    };

    return (
        <div className="alert_popup_container">
            <div className="alert_popup_background" onClick={() => setAlertOpen(false)} />
            <div className="alert_popup">
                <div className="alert_header">
                    <div className="alert_info">
                        {platformName && (
                            <div className="alert_platform">
                                <div className="alert_platform_icon"
                                    style={{
                                        backgroundImage: "url('" + (
                                            platformName === "Stubhub" ? StubhubIcon :
                                            platformName === "Viagogo" ? ViagogoIcon :
                                            platformName === "Sports Events 365" ? SportsEvents365Icon :
                                            platformName === "Live Football Tickets" ? LiveFootballTicketsIcon :
                                            platformName === "WOW" ? WowIcon :
                                            platformName === "Football Ticket Net" ? FootballTicketNetIcon :
                                            platformName === "P1" ? P1Icon :
                                            ""
                                        ) + "')"
                                    }}
                                />
                                <span>{platformName}</span>
                            </div>
                        )}
                        {!platformName && <span className="notif_span">We will let you know when the prices drop for</span>}
                        <h1>{eventName}</h1>
                        <span>{eventDate ? `${formatDate(eventDate)} at ${formatTime(eventTime)}` : ''}</span>
                    </div>
                    <div className="alert_popup_close" onClick={() => setAlertOpen(false)}>
                        <CloseIcon />
                    </div>
                </div>
                <div className="alert_email">
                    <input
                        className={alertError ? "input_error" : ""}
                        type="email"
                        placeholder="Email"
                        value={alertEmail}
                        onChange={(e) => setAlertEmail(e.target.value)}
                    />
                    <div className={"btn" + (alertLoading ? " btn_loading" : "")} id="send_alert" onClick={handleAlert}>
                        Confirm
                    </div>
                </div>
                {alertSend && (
                    <div className="alert_validation">
                        <div className="alert_validation_wrapper">
                            <CheckIcon />
                            <span>Yes, we will keep you notified!</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default EventAlertPopup;
