import React from "react";

function InstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24.005"
      viewBox="918 1559 24 24.005"
    >
      <g fillRule="evenodd" data-name="Group 34">
        <path
          fill="#0e3aff"
          d="M919.565 1560.667c-1.88 1.953-1.495 4.028-1.495 10.332 0 5.235-.914 10.483 3.866 11.718 1.493.384 14.72.384 16.21-.002 1.99-.514 3.61-2.128 3.83-4.943.032-.393.032-13.147 0-13.548-.236-2.998-2.081-4.726-4.513-5.076-.558-.08-.67-.105-3.53-.11-10.143.005-12.366-.446-14.368 1.629z"
          data-name="Path 19"
        ></path>
        <path
          fill="#fff"
          d="M930.033 1562.177c-3.617 0-7.051-.321-8.363 3.045-.542 1.39-.463 3.197-.463 5.783 0 2.269-.073 4.402.463 5.781 1.309 3.369 4.771 3.046 8.361 3.046 3.464 0 7.035.36 8.363-3.046.542-1.404.463-3.183.463-5.781 0-3.449.19-5.675-1.482-7.346-1.694-1.694-3.984-1.482-7.346-1.482zm-.79 1.591c7.544-.012 8.504-.85 7.974 10.8-.188 4.122-3.326 3.67-7.183 3.67-7.032 0-7.234-.202-7.234-7.237 0-7.117.557-7.229 6.442-7.235zm5.502 1.465a1.059 1.059 0 100 2.118 1.059 1.059 0 000-2.118zm-4.712 1.238a4.533 4.533 0 100 9.066 4.533 4.533 0 000-9.066zm0 1.591c3.89 0 3.895 5.885 0 5.885-3.889 0-3.895-5.885 0-5.885z"
          data-name="Path 20"
        ></path>
      </g>
    </svg>
  );
}

export default InstagramIcon;
