import React from "react";

function FooterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1920"
      height="73"
      fill="none"
      viewBox="0 0 1920 73"
    >
      <path
        fill="#EFF4FF"
        fillRule="evenodd"
        d="M1943.34 73C1226.27-53.634 414.303 10.045-7.435 73H1943.34z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default FooterIcon;
