import React from "react";

function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.299"
      height="28.277"
      viewBox="286 142 27.299 28.277"
    >
      <path
        fill="#0e3aff"
        fillRule="evenodd"
        d="M286.415 167.756l6.73-7a11.38 11.38 0 01-2.679-7.339c0-6.295 5.122-11.417 11.417-11.417s11.416 5.122 11.416 11.417-5.121 11.417-11.416 11.417c-2.364 0-4.616-.713-6.541-2.066l-6.781 7.052a1.478 1.478 0 01-1.073.457 1.49 1.49 0 01-1.073-2.521zm15.468-22.777c-4.653 0-8.439 3.785-8.439 8.438 0 4.653 3.786 8.438 8.439 8.438 4.653 0 8.438-3.785 8.438-8.438 0-4.653-3.785-8.438-8.438-8.438z"
        data-name="Path 38"
      ></path>
    </svg>
  );
}

export default SearchIcon;
