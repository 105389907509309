import React from "react";

function FacebookBlogIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#000243"
        d="M14.57.07h-3.114C9.61.07 7.554.861 7.554 3.591c.009.951 0 1.863 0 2.888H5.416v3.468H7.62v9.982h4.049V9.882h2.672l.242-3.412H11.6s.007-1.517 0-1.958c0-1.079 1.101-1.017 1.168-1.017h1.804V.069h-.002z"
      ></path>
    </svg>
  );
}

export default FacebookBlogIcon;
