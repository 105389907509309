import React from "react";

function MinusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="4"
      fill="none"
      viewBox="0 0 26 4"
    >
      <path
        fill="#0E3AFF"
        d="M14.573.428h-12.6a1.572 1.572 0 000 3.144h22.055a1.572 1.572 0 000-3.144h-9.455z"
      ></path>
    </svg>
  );
}

export default MinusIcon;
