import React, { useState, useEffect } from "react";
import ContactIcon from "../icons/contact";
import FacebookContactIcon from "../icons/facebook_contact";
import TwitterContactIcon from "../icons/twitter_contact";
import { Helmet } from "react-helmet";

function Contact() {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Support</title>
                <meta name="description" content={`We are ready for everything. If you have a problem or a question, click one of the channels to reach out to us and we will help you as soon as possible.`} />
            </Helmet>
            <div id="contact_box">
                <div class="wrapper">
                    <h1>Contact Us</h1>
                </div>
            </div>
            <div class="wrapper">
                <div className="contact_address">
                    <span>Address</span>
                    <span>Ticketseal by TBJB Limited 7, Triq il-Modd, Ibragg, Swieqi SWQ2373, Malta</span>
                </div>
                <div id="contact">
                    <a class="contact_card" href={"mailto:info@ticketseal.com"}>
                        <ContactIcon />
                        <h2>Email Support</h2>
                        <span>info@ticketseal.com</span>
                    </a>
                    <a class="contact_card" href="https://www.facebook.com/profile.php?id=61560516724020" target="_blank">
                        <FacebookContactIcon />
                        <h2>Facebook</h2>
                        <span>@ticketseal</span>
                    </a>
                    <a class="contact_card" href="https://x.com/TicketSeal_hi" target="_blank">
                        <TwitterContactIcon />
                        <h2>X</h2>
                        <span>@ticketseal</span>
                    </a>
                </div>
            </div>
        </>
    );
}

export default Contact;
