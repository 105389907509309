import React, { useState, useEffect, useRef } from 'react';

import FacebookBlogIcon from "../../icons/facebook_blog";
import InstagramBlogIcon from "../../icons/instagram_blog";
import LinkedinBlogIcon from "../../icons/linkedin_blog";

import { InstagramEmbed, YouTubeEmbed, TikTokEmbed } from 'react-social-media-embed';

import axios from "axios";
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Article() {
  const { blogId } = useParams();
  const [articleLoading, setArticleLoading] = useState(true);
  const [articleData, setArticleData] = useState(null);
  const scrollLengthRef = useRef(null);
  const articleEndRef = useRef(null);

  useEffect(() => {
    fetchArticleDetails(blogId);
  }, [blogId]);

  const fetchArticleDetails = async (blogId) => {
    try {
      const response = await axios.post('https://7sq9r5uhnl.execute-api.eu-north-1.amazonaws.com/public/api/blog/details', {
        blog_id: blogId,
      });

      const data = response.data.body;

      // Update state with article data
      setArticleData(JSON.parse(data));
      setArticleLoading(false);
    } catch (error) {
      console.error('Error fetching blog details:', error);
      setArticleLoading(false);
    }
  };

  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split('-');
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return `${months[parseInt(month) - 1]} ${parseInt(day)}, ${year}`;
  };

  const handleScroll = () => {
    if (!scrollLengthRef.current || !articleEndRef.current) return;

    const articleEndPosition = articleEndRef.current.getBoundingClientRect().top + window.scrollY;
    const articleStart = document.documentElement.scrollTop || document.body.scrollTop;
    const viewportHeight = window.innerHeight;
    const maxScrollableDistance = articleEndPosition - viewportHeight;

    // Calculate the scroll percentage
    const scrollPercent = Math.min((articleStart / maxScrollableDistance) * 100, 100);

    // Set the width of the scroll length
    scrollLengthRef.current.style.width = `${scrollPercent}%`;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [articleData]);

  if (articleLoading || !articleData) {
    return (
      <>
        <div id="article_header" className="article_header_skeleton" />
      </>
    );
  }

  const { blog_info, blog_content } = articleData;
  const { title, category, category_color, creation_date, update_date, author } = blog_info;

  return (
    <>
      {
        blogId == "83c854341b" &&
        <Helmet>
          <meta charSet="utf-8" />
          <title>P1Travel Review: Is P1Travel Legit? Our Honest Experience | Ticketseal.com</title>
          <meta name="description" content="Wondering if P1Travel is legit? We tested it ourselves. Read our in-depth review on Ticketseal.com to see if P1Travel delivers on its promises, including online reviews, pricing, and booking experience." />
        </Helmet>
      }
      {
        blogId == "22f9aa7690" &&
        <Helmet>
          <meta charSet="utf-8" />
          <title>How to Buy Last-Minute Football Tickets | A Guide by Ticketseal.com</title>
          <meta name="description" content="Learn the best strategies for securing last-minute football tickets. Our guide covers tips to save money, find premium seats, and avoid missing out. Follow our step-by-step process to get the best deals on football tickets, even at the last minute." />
        </Helmet>
      }
      <div className="scroll_length_container">
        <div className="scroll_length" ref={scrollLengthRef}></div>
      </div>
      <div id="article_header" style={{ backgroundImage: `url('https://ticketseal-blog-bucket.s3.amazonaws.com/header/${blog_info.blog_id}')` }}>
        <div className="article_header_flex">
          <div className="article_h1_container">
            <div className="article_h1_wrapper">
              <div className="blog_header_tag" style={{ backgroundColor: category_color }}>{category}</div>
              <h1>{title}</h1>
            </div>
          </div>
        </div>
      </div>
      <div id="article">
        <div id="article_body">
          <div className="article_main_container">
            <div id="article_main">
              <div className="article_links">
                <span className="article_link">Ticketseal.com</span>
                <span>{">"}</span>
                <span className="article_link">Blog</span>
                <span>{">"}</span>
                <span className="article_link">{category}</span>
                <span>{">"}</span>
                <span className="article_link">{title}</span>
              </div>
              <div className="article_social">
                <div className="article_category" style={{ backgroundColor: category_color }}>{category}</div>
                <div className="article_social_button">
                  <FacebookBlogIcon />
                </div>
                <div className="article_social_button">
                  <InstagramBlogIcon />
                </div>
                <div className="article_social_button">
                  <LinkedinBlogIcon />
                </div>
              </div>
              <div className="article_details">
                <div className="article_author">
                  <div className="article_author_avatar" style={{ backgroundImage: `url('https://ticketseal-blog-bucket.s3.amazonaws.com/author/${blog_info.blog_id}')` }} />
                  <span>{author}</span>
                </div>
                <div className="article_date">
                  <span>Published on:</span>
                  <div className="article_date_wrapper">{formatDate(creation_date)}</div>
                </div>
                {update_date && (
                  <div className="article_date">
                    <span>Updated on:</span>
                    <div className="article_date_wrapper">{formatDate(update_date)}</div>
                  </div>
                )}
              </div>
              <div className="article_content">
                {blog_content.map((content, index) => (
                  <React.Fragment key={index}>
                    {content.content_type === "h1" && <h1>{content.content}</h1>}
                    {content.content_type === "h2" && <h2>{content.content}</h2>}
                    {content.content_type === "h3" && <h3>{content.content}</h3>}
                    {content.content_type === "p" && <p>{content.content}</p>}
                    {content.content_type === "list" && <ul><li>{content.content}</li></ul>}
                    {content.content_type === "image" && <img src={`https://ticketseal-blog-bucket.s3.amazonaws.com/image/${content.content}`} alt="" />}
                    {content.content_type === "instagram" && (
                      <div className="instagram_post">
                        <InstagramEmbed url={content.content} width={400} />
                      </div>
                    )}
                    {content.content_type === "youtube" && (
                      <div className="youtube_embed">
                        <YouTubeEmbed url={content.content} />
                      </div>
                    )}
                    {content.content_type === "tiktok" && (
                      <div className="tiktok_embed">
                        <TikTokEmbed url={content.content} width={325} />
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
              <div className="article_end" ref={articleEndRef}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Article;
