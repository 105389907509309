import React from "react";

function HamburgerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M105.869-219.086v-79.784h748.827v79.784H105.869zm0-221.305v-79.218h748.827v79.218H105.869zm0-220.739v-79.784h748.827v79.784H105.869z"></path>
    </svg>
  );
}

export default HamburgerIcon;
