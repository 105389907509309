import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import SearchBox from '../Search/SearchBox';
import BreadcrumbsIcon from '../../icons/breadcrumbs';
import LocationIcon from '../../icons/location';
import CalendarIcon from '../../icons/calendar';
import ArrowDownIcon from '../../icons/arrow_down';
import AlertIcon from '../../icons/alert';
import VerifiedIcon from "../../icons/verified";

import P1 from "../../images/p1.png";
import LiveFootballTickets from "../../images/livefootballtickets.png";
import Viagogo from "../../images/Viagogo.png";
import Wow from "../../images/wow.png";
import SportsEvents365 from "../../images/sportsevents365.png";
import Stubhub from "../../images/stubhub.jpg";
import FootballTicketNet from "../../images/footballticketnet.jpg";

import { useCurrency } from '../../utils/currencyUtils';

import EuroIcon from '../../icons/euro';
import PoundIcon from '../../icons/pound';
import DollarIcon from '../../icons/dollar';

import EventAlertPopup from './EventAlertPopup';
import { Helmet } from 'react-helmet';

function Event() {

  const navigate = useNavigate();

  const { currency, currencyCharacter, exchangeRates, handleCurrencyChange, convertPrice } = useCurrency();
  const { groupId } = useParams();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [vistors, setVisitors] = useState(Math.floor(Math.random() * (150 - 50 + 1)) + 50);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertPlatform, setAlertPlatform] = useState(null);

  const platforms = ["Stubhub", "Viagogo", "Football Ticket Net", "Live Football Tickets", "Sports Events 365", "WOW", "P1"];

  useEffect(() => {
    const payload = { groupId };

    fetch('https://7sq9r5uhnl.execute-api.eu-north-1.amazonaws.com/public/api/event', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        if (!response.ok) {
          // throw new Error('Network response was not ok');
          navigate("/");
        }
        return response.json();
      })
      .then(jsonData => {
        console.log(jsonData);
        const data = JSON.parse(jsonData.event);

        console.log(data);

        const prices = platforms
          .filter(platform => data[`${platform.toLocaleLowerCase().replaceAll(" ", "")}_url`]) // Filter out platforms with null or undefined URLs
          .map(platform => ({
            platform: platform,
            price: data[`${platform.toLocaleLowerCase().replaceAll(" ", "")}_price`],
            url: data[`${platform.toLocaleLowerCase().replaceAll(" ", "")}_url`]
          }));

        setData({
          competition_id: data.competition_id,
          competition_name: data.competition_name,
          home_team_id: data.home_team_id,
          home_team: data.home_team,
          away_team_id: data.away_team_id,
          away_team: data.away_team,
          prices: prices,
          pricesInfo: calculatePrices(data),
          date: data.event_date,
          time: data.event_time,
          location: {
            venue: data.venue,
            city: data.city,
            country: data.country
          }
        });
        setLoading(false);
      })
      .catch(error => {
        navigate("/");
        setError(error);
        setLoading(false);
      });
  }, [groupId]);

  const calculatePrices = (data) => {

    const validPrices = platforms
      .map(platform => platform.toLocaleLowerCase().replaceAll(" ", ""))
      .map(item => item + "_price")
      .map(key => data[key])
      .filter(price => price !== null && price !== undefined && price.length > 0 && price != '0')
      .map(price => parseFloat(price));

    if (validPrices.length === 0) {
      return { min: 0, max: 0, avg: 0 }
    }

    const sum = validPrices.reduce((acc, price) => acc + price, 0);
    const min = Math.min(...validPrices).toFixed(2);
    const max = Math.max(...validPrices).toFixed(2);
    const avg = (sum / validPrices.length).toFixed(2);

    return { min, max, avg }
  };

  const convertDate = (dateString) => {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const [year, month, day] = dateString.split("-");
    const monthName = months[parseInt(month, 10) - 1];

    return `${monthName} ${parseInt(day, 10)}, ${year}`;
  };

  const convertTime = (timeString) => {
    let [hours, minutes] = timeString.split(":");
    hours = parseInt(hours, 10);
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    if (hours && minutes && period)
      return `${hours}:${minutes} ${period}`;
  };

  const makePathFriendly = (str) => {
    return str
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, '')
      .trim()
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
  }

  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownActive(!isDropdownActive);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownActive(false);
    }
  };

  const handleCurrencyClick = (newCurrency) => {
    handleCurrencyChange(newCurrency);
    setIsDropdownActive(false); // Hide the dropdown
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openAlert = (platformName) => {
    setAlertPlatform(platformName);
    setAlertOpen(true);
  }

  if (loading) {
    return <>
      <div className="search_box_skeleton skeleton_after"></div>
      <div className="wrapper wrapper_skeleton">
        <div className="breadcrumbs_skeleton skeleton_after"></div>
        <div className="event_vs_skeleton skeleton_after"></div>
      </div>
    </>
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cheap {data.home_team} vs {data.away_team} Football Tickets in 2024{data.pricesInfo.min > 0 ? " from " + currencyCharacter + parseFloat(convertPrice(data.pricesInfo.min)).toFixed(2) : ""}</title>
        <meta name="description" content={`Looking for the best deals for ${data.home_team} vs ${data.away_team} Football tickets? Compare trusted platforms for cheap tickets. Find the best offers, compare prices, and save now!`} />
      </Helmet>
      <SearchBox
        searchText={<>Cheap {data.home_team} vs {data.away_team} tickets{data.pricesInfo.min > 0 ? " from " + currencyCharacter + parseFloat(convertPrice(data.pricesInfo.min)).toFixed(2) : ""}</>}
        specialLeft
      />
      <div className="wrapper">
        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}

        <div className="breadcrumbs">
          {
            data.competition_name &&
            <>
              <Link to={"/" + makePathFriendly(data.competition_name) + "-tickets/league/" + data.competition_id} className="breadcrumbs_text">{data.competition_name}</Link>
              <BreadcrumbsIcon />
            </>
          }
          <span className="breadcrumbs_text">{data.home_team} vs {data.away_team} Tickets</span>
        </div>

        <div className="event_vs">
          <div className="event_vs_header">
            <div className="event_vs_props">
              {
                (data.location.venue || data.location.city || data.location.country) &&
                <div className="event_vs_prop">
                  <LocationIcon />
                  <span>
                    {data.location.venue}
                    {(data.location.venue && (data.location.city || data.location.country)) && " in "}
                    {data.location.city}
                    {(data.location.city && data.location.country) && ", "}{data.location.country}
                  </span>
                </div>
              }
              {
                data.date &&
                <div className="event_vs_prop">
                  <CalendarIcon />
                  <span>{convertDate(data.date)}</span>
                </div>
              }
            </div>
          </div>
          <div className="event_vs_body">
            <div className="event_vs_team">
              <Link to={"/" + makePathFriendly(data.home_team) + "-tickets/club/" + makePathFriendly(data.home_team_id)} className="event_vs_team_logo_container">
                <div
                  className="event_vs_team_logo"
                  style={{ backgroundImage: "url('https://ticketseal-blog-bucket.s3.eu-north-1.amazonaws.com/clubs/club" + (data.home_team_id) + ".png')" }}
                />
              </Link>
              <Link to={"/" + makePathFriendly(data.home_team) + "-tickets/club/" + makePathFriendly(data.home_team_id)}>{data.home_team}</Link>
            </div>
            <div className="event_vs_icon_container">
              <span className="event_vs_value">VS</span>
              {
                data.time && convertTime(data.time) &&
                <span className="event_time">{convertTime(data.time)}</span>
              }
            </div>
            <div className="event_vs_team">
              <Link to={"/" + makePathFriendly(data.away_team) + "-tickets/club/" + makePathFriendly(data.away_team_id)}>{data.away_team}</Link>
              <Link to={"/" + makePathFriendly(data.away_team) + "-tickets/club/" + makePathFriendly(data.away_team_id)} className="event_vs_team_logo_container">
                <div
                  className="event_vs_team_logo"
                  style={{ backgroundImage: "url('https://ticketseal-blog-bucket.s3.eu-north-1.amazonaws.com/clubs/club" + (data.away_team_id) + ".png')" }}
                />
              </Link>
            </div>
          </div>
          <div className="event_vs_footer">
            {
              data.pricesInfo.avg > 0 &&
              <div className="event_vs_button">
                <div className="event_vs_button_bg"></div>
                <span>Avrage Ticket Price: {parseFloat(convertPrice(data.pricesInfo.avg)).toFixed(2)} {currency}</span>
              </div>
            }
            <div className="event_dropdown_container" ref={dropdownRef}>
              <div
                className="event_dropdown"
                onClick={toggleDropdown}
              >
                {currency === "EUR" && <EuroIcon />}
                {currency === "USD" && <DollarIcon />}
                {currency === "GBP" && <PoundIcon />}
                <ArrowDownIcon />
              </div>
              <div className={`event_dropdown_list ${isDropdownActive ? 'active' : ''}`}>
                <span onClick={() => handleCurrencyClick("EUR")}>EUR</span>
                <span onClick={() => handleCurrencyClick("USD")}>USD</span>
                <span onClick={() => handleCurrencyClick("GBP")}>GBP</span>
              </div>
            </div>
            <div className="event_button event_alert_btn" onClick={() => openAlert(null)}>
              <AlertIcon />
              <span>Set Price Alert</span>
            </div>
          </div>
        </div>

        <div className="event_description">
          <h3>Where to buy {data.home_team} vs {data.away_team} Tickets</h3>

          {data.pricesInfo.min === 0 && data.pricesInfo.max === 0 ? (
            <p>Unfortunately, tickets for {data.home_team} vs {data.away_team} are currently sold out. Please check back later, or use our alert system to be notified if tickets become available again. Don't miss out on the opportunity to attend this exciting match—set up your alert now!</p>
          ) : data.pricesInfo.min === data.pricesInfo.max ? (
            <p>
              Ticket prices for {data.home_team} vs {data.away_team} are currently fixed at
              {currency === "EUR" && ` €${data.pricesInfo.min}`}
              {currency === "USD" && ` $${data.pricesInfo.min}`}
              {currency === "GBP" && ` £${data.pricesInfo.min}`}.
              This means you can lock in your seats at a guaranteed price. Check out the options below to secure your spot for this exciting match!
            </p>
          ) : (
            <p>
              Finding the best deal for {data.home_team} vs {data.away_team} tickets is easy with our platform. We've compared prices from trusted sellers, so you can choose the best option for your budget. Right now, ticket prices range from
              {currency === "EUR" && ` €${data.pricesInfo.min}`}
              {currency === "USD" && ` $${data.pricesInfo.min}`}
              {currency === "GBP" && ` £${data.pricesInfo.min}`}
              to
              {currency === "EUR" && ` €${data.pricesInfo.max}`}
              {currency === "USD" && ` $${data.pricesInfo.max}`}
              {currency === "GBP" && ` £${data.pricesInfo.max}`}. These prices are updated regularly, so you might find a better deal closer to the event. Don't miss out on this exciting match—check out the options below!
            </p>
          )}
        </div>

        <div className="event_platforms">
          {
            data.prices
              .sort((a, b) => parseFloat(a.price) - parseFloat(b.price)) // Sort based on price
              .map(price => (
                <div className="event_platform">
                  <div className="event_platform_logo_container">
                    <div className="event_platform_logo" style={{
                      backgroundImage: "url('" + (
                        price.platform == "Stubhub" ? Stubhub :
                          price.platform == "Viagogo" ? Viagogo :
                            price.platform == "Football Ticket Net" ? FootballTicketNet :
                              price.platform == "Live Football Tickets" ? LiveFootballTickets :
                                price.platform == "Sports Events 365" ? SportsEvents365 :
                                  price.platform == "WOW" ? Wow :
                                    price.platform == "P1" ? P1 : ""
                      ) + "')"
                    }}></div>
                  </div>
                  <div className="event_platform_title">
                    <span>{price.platform}</span>
                    <VerifiedIcon />
                  </div>
                  <div className="event_platform_price">{price.price ? (parseFloat(convertPrice(price.price)).toFixed(2) + " " + currency) : "Sold out"}</div>
                  {
                    price.price
                      ?
                      <a className="event_platform_btn" href={price.url} target="_blank">
                        <span>Show Ticket</span>
                      </a>
                      :
                      <div className="event_platform_btn event_platform_btn_outline" onClick={() => openAlert(price.platform)}>
                        <span>Keep Me Alert</span>
                      </div>
                  }
                </div>
              ))
          }
        </div>
      </div>

      {
        alertOpen &&
        <EventAlertPopup
          eventName={data.home_team + " vs " + data.away_team}
          platformName={alertPlatform}
          eventDate={data.date}
          eventTime={data.time}
          groupId={groupId}
          setAlertOpen={setAlertOpen}
        />
      }

    </>
  )
}

export default Event