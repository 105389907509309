import React from "react";

function CustomerSupportIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 122.88 122.88"
      version="1.1"
      viewBox="0 0 122.88 122.88"
      xmlSpace="preserve"
    >
      <path
        fillRule="evenodd"
        d="M61.44 0c16.97 0 32.33 6.88 43.44 18 11.12 11.12 18 26.48 18 43.44 0 16.96-6.88 32.33-18 43.44-11.12 11.12-26.48 18-43.44 18-16.97 0-32.33-6.88-43.44-18C6.88 93.77 0 78.41 0 61.44S6.88 29.11 18 18C29.11 6.88 44.47 0 61.44 0zM48.1 56.19c2 3.6 4.3 7.06 7.29 10.21 2.99 3.17 6.72 6.06 11.55 8.51.35.18.7.18 1 .05.46-.18.92-.55 1.39-1.01.35-.35.8-.93 1.27-1.55 1.85-2.44 4.15-5.47 7.39-3.96.07.03.13.07.2.11L89 74.77c.03.02.07.05.11.07 1.43.98 2.02 2.49 2.03 4.2 0 1.75-.64 3.71-1.58 5.37-1.25 2.19-3.08 3.63-5.2 4.6-2.01.92-4.26 1.43-6.41 1.74-3.38.5-6.55.18-9.8-.82-3.17-.98-6.37-2.6-9.85-4.76l-.25-.16c-1.6-1-3.33-2.07-5.03-3.33-6.21-4.68-12.54-11.45-16.65-18.9-3.46-6.26-5.34-13.01-4.32-19.44.57-3.53 2.09-6.74 4.72-8.85 2.3-1.85 5.4-2.87 9.41-2.51.46.03.87.3 1.09.7l6.94 11.72c1.01 1.32 1.14 2.62.59 3.92-.46 1.07-1.39 2.05-2.66 2.98-.37.32-.82.64-1.29.98-1.55 1.12-3.32 2.42-2.71 3.96l-.04-.05z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CustomerSupportIcon;
