import React from "react";

function ArrowDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#0E3AFF"
        d="M11.5 13.092L6.444 8.001 5 9.455l6.5 6.546L18 9.455l-1.444-1.454-5.056 5.091z"
      ></path>
    </svg>
  );
}

export default ArrowDownIcon;
